import React, { useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Card, Button, Grid, Header, Message, Form, Menu, Breadcrumb, Loader, Progress, Statistic } from 'semantic-ui-react'; 
import LoadingSegment from '../../../Components/LoadingSegment';
import ErrorSegment from '../../../Components/ErrorSegment';
import { useHistory, useParams } from 'react-router-dom';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { cpuUsage } from 'process';
import { Heatmap } from 'reaviz';

const transitionmatrix = [ [ 0.0, 1, 0.002890173410404624, 0.16666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0 ], [ 0.0, 0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0 ], [ 0.0, 1, 0.40173410404624277, 0.0, 0.8333333333333334, 0.10638297872340426, 0.026465028355387523, 0.047619047619047616, 0.3333333333333333, 0.0 ], [ 0.0, 1, 0.005780346820809248, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0 ], [ 0.0, 0, 0.014450867052023121, 0.0, 0.0, 0.0, 0.001890359168241966, 0.0, 0.0, 0.0 ], [ 0.0, 1, 0.06069364161849711, 0.0, 0.0, 0.0, 0.022684310018903593, 0.14285714285714285, 0.0, 0.0 ], [ 0.2857142857142857, 1, 0.1416184971098266, 0.0, 0.0, 0.18085106382978725, 0.12098298676748583, 0.047619047619047616, 0.0, 0.0 ], [ 0.0, 1, 0.011560693641618497, 0.0, 0.0, 0.010638297872340425, 0.0, 0.047619047619047616, 0.3333333333333333, 0.0 ], [ 0.14285714285714285, 1, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0 ], [ 0.5714285714285714, 0, 0.36127167630057805, 0.8333333333333334, 0.16666666666666666, 0.7021276595744681, 0.8279773156899811, 0.7142857142857143, 0.3333333333333333, 0.0 ] ];
const channelstates = [ '(Other)', 'Conversion', 'Direct', 'Display', 'Email', 'Organic', 'Paid Search', 'Referral', 'Social', 'Start' ];

const colorPal = [ '#3cbeaf', '#007869', '#ffb400', '#727272', '#9da0a5', '#d5d5d5', '#225ea8', '#253494', '#081d58' ];
const model_map = {
	"O(1) Score": {
		key: 'model_markov',
		label: "Markov R1", 
	},
	"O(2) Score": {
		key: 'model_markov',
		label: "Markov R2", 
	},
	"O(1) Removal Effect": {
		key: 'model_markov',
		label: "Markov R1 RE", 
	},
	"O(2) Removal Effect": {
		key: 'model_markov',
		label: "Markov R2 RE", 
	},
	first: {
		key: 'model_first',
		label: "First Touch", 
	},
	last: {
		key: 'model_last',
		label: "Last Touch", 
	},
	last_ndr: {
		key: 'model_lastNdr',
		label: "Last Non-Direct Touch", 
	},
	linear: {
		key: 'model_linear',
		label: "Linear", 
	},
};
const ModelComparison: React.FC<{data, csv: string}> = ( { data, csv } ) => {
	const [ state, setState ] = useState<{
        stacked: boolean
        model_first:boolean,
        model_last:boolean
        model_lastNdr:boolean,
        model_linear: boolean,
        model_markov: boolean,
		model_shapley: boolean,
        model_survival: boolean,
        heatmapData?: any
        holdout?: {
            csv:string
            beforeRows: number
            afterRows: number
            excluded: any
            afterConv: number
            beforeConv: number
            show: boolean,
            loading?: boolean
        }
    }>( {
    	stacked: false,
    	model_first: true,
    	model_last: true,
    	model_lastNdr: true,
    	model_linear: true,
    	model_markov: true,
    	model_shapley: false,
    	model_survival: false,
    } );
	const history = useHistory();
	const finalJson = {};
	const chartData = [];
	let channels = [];
	const parsedData = JSON.parse( data );
	for ( const model in parsedData ) {
		if ( model_map[ model ] && state[ model_map[ model ].key ] ) {
			console.log( model_map[ model ] );
			chartData.push( {
				name: model_map[ model ].label, 
				...parsedData[ model ],
			} );
		}
        
		channels = [ ...channels, ...Object.keys( parsedData[ model ] ) ];
	}
    
	channels = Array.from( new Set( channels ) ).filter( c=>c !== "Start" && c !== "Conversion" );
	// const channels = state.modelResult ? Object.keys( Object.keys( JSON.parse( state.modelResult ) )[ 0 ] ) : null;
    
	// data.map( ( v, k ) => {
	// 	const ch = v;

	// 	ch.map( ( val, key ) => ch[ key ] = parseFloat( ( val * 100 ).toFixed( 2 ) ) );
	// 	ch.name = k;
	// 	if ( ! k.match( /Removal|Start|Conversion|name/ ) ) {
	// 		chartobj.push( ch );
	// 		// console.log(ch);
	// 		v.map( ( val, key ) => {
	// 			finalJson[ key ] ? finalJson[ key ] = [ ...finalJson[ key ], val ] : finalJson[ key ] = [ key, val ];
	// 		} );
	// 	}
	// } );
	// Object.keys( finalJson ).map( k => {
	// 	if ( ! ( k.match( /Removal|Start|Conversion|name/ ) ) ) {
	// 		series.push( { field: k } );
	// 	}
	// } );
	// console.log( chartobj );
	// console.log( finalJson );
	// console.log( finalJson );
	const handleChange = ( e, data ) => setState( { ...state, [ data.name ]: data.value ? data.value : ! state[ data.name ] } );
	const handleHoldout = ( value ) => {
		setState( { ...state, holdout: { ...state.holdout, loading: true } } );
		const excluded = ( state.holdout && state.holdout.excluded ? state.holdout.excluded : [] );
		const idx = excluded.indexOf( value );
		let beforeConv = 0;
		let afterConv = 0;
		if ( idx >= 0 )
			excluded.splice( idx );
		else
			excluded.push( value );
		let rows = [];
		let c = 0;
		for ( const row of csv.split( '\n' ).slice( 1 ) ) {
			beforeConv += parseInt( row.split( ',' )[ 3 ] );
			c = 0;
			for ( const chan of excluded ) {
				if ( row.includes( chan ) ) {
					c++;
				}
			}
			if ( c === 0 ) {
				afterConv += parseInt( row.split( ',' )[ 3 ] );
				rows.push( row );
			}
		}
		if ( excluded.length === 0 ) {
			rows = csv.split( '\n' );
		}
		setTimeout( ()=>							setState( { ...state, holdout: {
			csv: rows.join( "\n" ),
			beforeRows: csv.split( '\n' ).length,
			afterRows: rows.length,
			afterConv: afterConv,
			beforeConv: beforeConv,
			excluded: excluded,
			loading: false,
			show: true,
            
		} } ),
		400,
		);
	};
    
	const handleHeatmap = ( channels ) => {
		const heatmapData = transitionmatrix.map( ( n, i ) => ( {
			key: channelstates[ i ],
			data: n.map( ( d, j )=>( { key: channelstates[ j ], data: ( d * 100 ) } ) ),
		} ) );
		console.log( heatmapData );
		setState( { ...state, heatmapData: heatmapData } );
	};
	return (
		<>
			<Grid.Row>
				<Grid.Column>
					<Header as="h3">Model Credit Allocation</Header>
					<Header as="h4">Show/Hide Models</Header>
					<Form>
						<Form.Group inline>
							<label>Single Touch</label>
							<Form.Checkbox name="model_first" onChange={ handleChange } checked={ state.model_first } toggle label="First Touch" />
							<Form.Checkbox name="model_last" onChange={ handleChange } checked={ state.model_last } toggle label="Last Touch" />
							<Form.Checkbox name="model_lastNdr" onChange={ handleChange } checked={ state.model_lastNdr } toggle label="Last Non-Direct Touch" />
						</Form.Group>
						<Form.Group inline>
							<label>Positional</label>
							<Form.Checkbox name="model_linear" onChange={ handleChange } checked={ state.model_linear } toggle label="Linear" />
						</Form.Group>
						<Form.Group inline>
							<label>Data Driven</label>
							<Form.Checkbox name="model_markov" onChange={ handleChange } checked={ state.model_markov } toggle label="Variable Order Markov" />
							<Form.Checkbox disabled name="model_shapley" onChange={ handleChange } checked={ state.model_shapley } toggle label="Shapley Value" />
							<Form.Checkbox disabled name="model_survival" onChange={ handleChange } checked={ state.model_survival } toggle label="Survival Model" />
						</Form.Group>
					</Form>
					<Button content={ state.stacked ? "Unstack" : "Stack" } onClick={ ()=>setState( { ...state, stacked: ! state.stacked } ) } />
					<ResponsiveContainer width="100%" height={ 500 }> 
						<BarChart
							width={ 500 }
							height={ 300 }
							data={ chartData.filter( k=>! k.name.includes( 'RE' ) ) }
							margin={ {
								top: 20,
								right: 30,
								left: 20,
								bottom: 5,
							} }
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="name" />
							<YAxis domain={ [ 0, 'dataMax' ] } />
							<Tooltip formatter={ ( value, label ) => ( [ `${ Math.round( ( value * 10000 ) ) / 100 }%`, label ] ) } />
							<Legend />
							{ Object.keys( chartData[ Object.keys( chartData )[ 0 ] ] ).filter( d=>d !== 'name' && d !== 'Start' && d !== 'Conversion' ).map( ( d, i )=><Bar dataKey={ d } stackId={ state.stacked ? 'a' : null } fill={ colorPal[ i ] } /> ) }
							{ /* <Bar dataKey="uv" stackId="a" fill="#82ca9d" /> */ }
						</BarChart>
					</ResponsiveContainer>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column>
					<Header as="h3">Journey Analysis
						<Header.Subheader>Using the Variable Order Markov model, visualize the relationship between channels based on the different Journeys customers take to conversion.</Header.Subheader>
					</Header>
                    
					<Button primary content="Run Analysis" icon="calculator" onClick={ ()=>handleHeatmap( channels ) } />
					{ state.heatmapData ? 
						<>
							<Heatmap 
								height={ 600 }
								width={ 600 }
								data={ state.heatmapData } /></>  
						: null }
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column>
					<Header as="h3">Hold-Out Simulation
						<Header.Subheader>Using the Variable Order Markov model, simulate the conversion impact of "turning off" a single channel, or combination of channels.</Header.Subheader>
					</Header>
					<Form>
						<Form.Group inline>
							<label>Channels to Remove</label>
							{ channels.map( c=><Form.Checkbox key={ c }checked={ state.holdout && state.holdout.excluded && state.holdout.excluded.includes( c ) } onChange={ ()=> handleHoldout( c ) } label={ c } /> ) }
							
						</Form.Group>
						<Form.Button primary content="Build Simulation" icon="magic" loading={ state.holdout && state.holdout.loading } onClick={ ()=>{
							setState( { ...state, holdout: { ...state.holdout, loading: true } } );
							setTimeout( ()=>							setState( { ...state, holdout: { ...state.holdout, show: true, loading: false } } ),
								400,
							);
						} } />
					</Form>
					{
						state.holdout && state.holdout.show ?
							<Statistic.Group widths={ 5 } style={ { width: '100%', padding: '25px 0' } }>

								<Statistic>
									<Statistic.Value>{ `${ Math.round( ( state.holdout.afterConv / state.holdout.beforeConv ) * 100 ) }%` }</Statistic.Value>
									<Statistic.Label>Simulated Conversion Rate</Statistic.Label>
								</Statistic>
								<Statistic>
									<Statistic.Value>{ state.holdout.afterConv }</Statistic.Value>
									<Statistic.Label>Simulated Conversions</Statistic.Label>
								</Statistic>
								<Statistic color={ state.holdout.afterRows / state.holdout.beforeRows < 0.5 ? "red" : null }>
									<Statistic.Value >{ state.holdout.afterRows }</Statistic.Value>
									<Statistic.Label>Simulation Journeys</Statistic.Label>
								</Statistic> 
								<Statistic>
									<Statistic.Value>{ state.holdout.beforeConv }</Statistic.Value>
									<Statistic.Label>Total Conversions</Statistic.Label>
								</Statistic>
								<Statistic>
									<Statistic.Value>{ state.holdout.beforeRows }</Statistic.Value>
									<Statistic.Label>Total Journeys</Statistic.Label>
								</Statistic>
							</Statistic.Group> 
							: null
					}
				</Grid.Column>

			</Grid.Row>
		</>
	);
};

export default ModelComparison;
