import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getUserAuth } from '../Authentication/GetUser';

const PrivateRoute = ( { component: Component, ...rest }: any & { component: any } ) => {
	const [ user, setUser ] = useState( null );
	useEffect( ()=> {
		async function getUser() {
			const u = await getUserAuth( false );
			setUser( u );
		}
		getUser();
	}, [] );

	return (
		<Route
			{ ...rest }
			render={ props => {
				if ( typeof user === 'object'  ) {
					return 	<Component user={ user } { ...props } />;
				} else if ( user === null ) {
					return <div>Loading</div>;
				} 
				return <Redirect
					to={ {
						pathname: '/login',
						state: { referrer: window.location.pathname },
					} }
				/>;
			} }
		/>
	);
};

export default PrivateRoute;
