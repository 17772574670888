import React, { FC, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useParams } from "react-router-dom";
import TestForm from '../Forms/TestForm'

//This Control may no longer be needed.
const EditClientTest: FC = () => {
  return (
    <TestForm />
  )
}

export default EditClientTest;
