import React, { useState } from 'react';
import * as jwt from 'jsonwebtoken';
import { Button, Header, Image } from 'semantic-ui-react';
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import LoadingSegment from '../../Components/LoadingSegment';
import ErrorSegment from '../../Components/ErrorSegment';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import CompassLogo from '../../Images/Compass/logo_dark_green.svg';
interface State {
	active: string | null
}

const GET_METABASE_DASHBOARDS = gql`
query GetMetabaseDashboards {
	compass_metabase_dashboard(order_by: {order: asc}) {
	  metabase_id
	  dashboard_id
	  name
	}
  }
`;

const Metabase = () => {
	const [ state, setState ] = useState<State>( {
		active: null,
	} );
	const { loading, error, data, refetch } = useQuery( GET_METABASE_DASHBOARDS );
	const { dashboard_id } = useParams();
	const history = useHistory();

	if ( loading ) {
		return <LoadingSegment />;
	} else if ( error ) {
		return <ErrorSegment message={ JSON.stringify( error ) } />;
	}
	if ( data.compass_metabase_dashboard.length === 0 ) {
		return <ErrorSegment message={ 'No Dashboards Available' } />;
	}
	if ( process.env.REACT_APP_METABASE_SECRET_KEY && process.env.REACT_APP_METABASE_SITE_URL && data ) {
		if ( ! state.active ) setState( { active: data.compass_metabase_dashboard[ 0 ].name } );
		const payload = {
			resource: { dashboard: data.compass_metabase_dashboard.find( item=> item.dashboard_id === dashboard_id )?.metabase_id },
			params: {},
			exp: Math.round( Date.now() / 1000 ) + ( 10 * 60 ), // 10 minute expiration
		};
		const token = jwt.sign( payload, process.env.REACT_APP_METABASE_SECRET_KEY );
		const iframeUrl = process.env.REACT_APP_METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=false&titled=true";
		return (
			<>
				<Helmet>
					<title>Compass</title>
				</Helmet>
				{ /* <Menu pointing secondary className="headerAttached">
					{ data.compass_metabase_dashboard.map( ( item )=> (
						<Menu.Item
							key={ item.name }
							name={ item.name }
							active={ state.active === item.name ? true : false }
							onClick={ ()=>setState( { ...state, active: item.name } ) }
						/>
					) ) }
				</Menu> */ }
				<div className="compass">
					<div className="header-strip" style={ { padding: '15px', display: 'flex' } }>
						<Button basic content="Back" icon="arrow left" onClick={ ()=>history.goBack() } />
						<div style={ { margin: '0 auto', display: 'flex' } }>
							<Image src={ CompassLogo } style={ { height: '50px' } } />
							<Header as="h1" style={ { lineHeight: '0px', fontSize: ' 28px', marginLeft: '15px' } }>Compass</Header>
						</div>
						<Button basic content="Share" disabled icon="share" />
					</div>
				</div>
				<iframe
					title="Metabase"
					src={ iframeUrl }
					frameBorder="0"
					width="100%"
					height="90%"
				></iframe>
			</>
		);
	} 
	return (
		<div>
			<h1>This is the Dashboard View (missing vars for metabase demo)</h1>
		</div>
	);
};

export default Metabase;
