/* eslint-disable no-undef */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import App from './App';
import './Styles/app.scss';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './Store';
import 'semantic-ui-css/semantic.min.css';
/** Configure Apollo */
import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getUserAuth } from './Authentication/GetUser';

/** Configure amplify */
Amplify.configure( {
	Auth: {
		region: process.env.REACT_APP_AUTH_REGION || 'us-west-2',
		userPoolId: process.env.REACT_APP_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
	},
} );

const asyncAuthLink = setContext( async() => {
	// this is an async call, it will be done before each request
	const user = await getUserAuth( false );
	return {
		headers: {
			authorization: user !== undefined && user !== null ? `Bearer ${ user.signInUserSession.idToken.jwtToken }` : "", 
		},
	};
},
);

const httpLink = createHttpLink( {
	uri: process.env.REACT_APP_GRAPH_URL,
} );
console.log( 'Apollo init' );
const client = new ApolloClient( {
	link: asyncAuthLink.concat( httpLink ),
	cache: new InMemoryCache(),
} );

ReactDOM.render(
	<>
		<Provider store={ store }>
			<ApolloProvider client={ client }>
				<App />
			</ApolloProvider>
		</Provider>
	</>,
	document.getElementById( 'root' ),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
