import React, { useState } from 'react';
import * as jwt from 'jsonwebtoken';
import { Breadcrumb, Button, Card, Container, Header, Image } from 'semantic-ui-react';
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import LoadingSegment from '../../Components/LoadingSegment';
import ErrorSegment from '../../Components/ErrorSegment';
import { Helmet } from 'react-helmet';
import CompassLogoGreen from '../../Images/compass_logo_green.svg';
import FolderIcon from '../../Images/Compass/folder_icon.svg';
import { useHistory, useParams } from 'react-router-dom';
interface State {
	active: string | null
}

const GET_METABASE_DASHBOARDS = gql`
query MyQuery($folder_id: uuid!) {
    compass_client_folder_by_pk(folder_id: $folder_id) {
      folder_id
      name
      metabase_dashboards {
        dashboard_id
        favorite
        name
      }
    }
  }
  
`;

const Folders = () => {
	const history = useHistory();
	const { folder_id } = useParams();
	const { loading, error, data } = useQuery( GET_METABASE_DASHBOARDS, { variables: {
		folder_id,
	} } );
    
	console.log( data );
	if ( loading ) {
		return <LoadingSegment />;
	} else if ( error ) {
		return <ErrorSegment message={ JSON.stringify( error ) } />;
	}
	if ( data && data.compass_client_folder_by_pk ) {
		return (
			<div className="compass">
				<Helmet>
					<title>Compass</title>
				</Helmet>
				<div className="header-strip" style={ { display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', width: '100%', height: '350px', overflow: "hidden", textAlign: 'center', position: 'relative' } }>
					<Header as="h1" >Compass
						<Header.Subheader>Achieve clarity and insight for marketing performance
							reporting, analysis and decision-making.</Header.Subheader>
					</Header>
					<Image src={ CompassLogoGreen } style={ { position: 'absolute', right: '10px', bottom: '-120px' } } />
				</div>
				<Container style={ { padding: "25px 0 0 0" } }>
					<Breadcrumb>
						<Breadcrumb.Section link onClick={ ()=>history.goBack() }>Compass</Breadcrumb.Section>
						<Breadcrumb.Divider />
						<Breadcrumb.Section >{ data.compass_client_folder_by_pk.name }</Breadcrumb.Section>
					</Breadcrumb>
					<Header as="h3">Dashboards</Header>

					<div className="card-container">
						{ data.compass_client_folder_by_pk.metabase_dashboards.map( ( item )=> (
							<div className="nav-card" key={ item.dashboard_id } onClick={ ()=>history.push( `/compass/dashboard/${ item.dashboard_id }` ) }>
								<img src={ CompassLogoGreen } />
								<div className="card-name">
									{ item.name }
								</div>
							</div>
						) ) }
					</div>
				</Container>
			</div>
		);
	} 
	return (
		<div>
			<h1>This is the Dashboard View (missing vars for metabase demo)</h1>
		</div>
	);
};

export default Folders;
