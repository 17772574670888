import React from 'react';
import { Message } from 'semantic-ui-react';

const ErrorSegment: React.FC<{message:string}> = ( { message } ) => {
	return (
		<Message negative>
			<Message.Header>There was an error.</Message.Header>
			<p>{ message }</p>
			<p>Please contact aditya@wheelhousedmg.com if this message persists.</p>
		</Message>
	);
};

export default ErrorSegment;
