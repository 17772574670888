import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Portal from './Portal';
import Login from '../Views/Login/Login';
import SignUp from '../Views/Login/SignUp';
import ConfirmEmail from '../Views/Login/ConfirmEmail';
import ForgotPassword from '../Views/Login/ForgotPassword';
import PasswordReset from '../Views/Login/PasswordReset';
import PrivateRoute from './PrivateRoute';

const Routes: React.FC = () => {
	return (
		<Router>
			<Switch>
				<Route exact={ true } path="/login" component={ Login } />
				<Route exact={ true } path="/signup" component={ SignUp } />
				<Route exact={ true } path="/verify-code" component={ ConfirmEmail } />
				<Route exact={ true } path="/reset-password" component={ PasswordReset } />
				<Route exact={ true } path="/forgot-password" component={ ForgotPassword } />
				<PrivateRoute path="*" component={ Portal } />
			</Switch>
		</Router>
	);
};

export default Routes;
