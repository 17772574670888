import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Spin, Input, Button, notification, Row, Col } from 'antd';
import LoginGraphic from '../../Images/Graphics/LoginGraphic';
import LogoLogin from '../../Images/logo.png';

type Props = {
	form: any;
};

type State = {
	username: string;
	redirect: boolean;
	loading: boolean;
};

class ForgotPassword extends React.Component<Props, State> {
	state = {
		username: '',
		redirect: false,
		loading: false,
	};

	handleSubmit = ( event: React.FormEvent<Element> ) => {
		event.preventDefault();

		this.props.form.validateFields( ( err: { message: string }, values: { username: string } ) => {
			if ( ! err ) {
				const { username } = values;

				this.setState( {
					loading: true,
					username,
				} );

				Auth.forgotPassword( username )
					.then( data => {
						notification.success( {
							message: 'Redirecting you in a few!',
							description: 'Account confirmed successfully!',
							placement: 'topRight',
							duration: 1.5,
							onClose: () => {
								this.setState( { redirect: true } );
							},
						} );
					} )
					.catch( err => {
						notification.error( {
							message: 'User confirmation failed',
							description: err.message,
							placement: 'topRight',
							duration: 1.5,
						} );
						this.setState( { loading: false } );
					} );
			}
		} );
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { loading, redirect, username } = this.state;

		return (
			<React.Fragment>
				<div className="LoginBackground">
					<div className="LoginLeftSection">
						<div style={ { padding: '100px', position: 'absolute' } }>
							<img src={ LogoLogin } alt="login-logo" />
							<h1 style={ { color: 'white', fontSize: '55px' } }>Navigate your <br />marketing</h1>
						</div>
						<LoginGraphic />
					</div>
					<div className="LoginRightSection">
						<Form onSubmit={ this.handleSubmit } className="login-form FormWrapper">
							<Row gutter={ 16 } style={ { padding: '20px 10px' } }>
								<h2>Reset Password</h2>
								<p>We will send you a reset code by email</p>
							</Row>
							<Row gutter={ 16 } style={ { padding: '0px 10px' } }>
								<h5>Email Address</h5>
							</Row>
							<Form.Item>
								{ getFieldDecorator( 'username', {
									rules: [
										{
											required: true,
											message: 'Please input your Email Address!',
										},
									],
								} )(
									<Input prefix={ <UserOutlined /> } placeholder="Email" />,
								) }
							</Form.Item>
							<Form.Item className="text-center">
								<Row>
									<Col lg={ 24 }>
										<Button style={ { width: '100%' } } type="primary" htmlType="submit" className="login-form-button">
											{ loading ? (
												<Spin indicator={ <LoadingOutlined style={ { fontSize: 24 } } spin /> } />
											) : (
												'Reset Password'
											) }
										</Button>
									</Col>
									<Col lg={ 24 }>
										<Link to="/login">Ooh! Wait! I`&apos;`ve remembered!</Link>
									</Col>
								</Row>
							</Form.Item>
						</Form>
					</div>
				</div>
				{ redirect && (
					<Redirect
						to={ {
							pathname: '/reset-password',
							search: `?username=${ username }`,
						} }
					/>
				) }
			</React.Fragment>
		);
	}
}

export default Form.create()( ForgotPassword );
