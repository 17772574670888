// @ts-nocheck
//Disabled Typecheck because of a strange mismatch in the Mutation for the Step
import React, { FC, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Grid, Form, Input, Button, Confirm, Icon, Accordion, Select } from 'semantic-ui-react';
import { deepParseJson } from 'deep-parse-json';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/solarized_dark';
import { JsonEditor as Editor } from 'jsoneditor-react';



const GET_STEPS = gql`
query getSteps($step_id: uuid!) {
  sonar_step(where: {step_id: {_eq: $step_id}}) {
    test_id
    step_id
    name
    type
    action_type
    sequence
    config
  }
}
`;

const UPDATE_STEP = gql`
mutation UpdateStep($step_id: uuid!, $name: String!, $type: String!, $action_type: String!, $sequence: Int!, $config: jsonb!, $updated: timestamp!) {
  update_sonar_step(where: {step_id: {_eq: $step_id}}, _set: {name: $name, type: $type, action_type: $action_type, sequence: $sequence, config: $config, updated: $updated}){
    returning {
      step_id
      name
      type
      action_type
      config
      updated
    }
  }
}
`;

const DELETE_STEP= gql`
mutation DeleteStep($step_id: uuid!) {
  delete_sonar_step(where: {step_id: {_eq: $step_id}}) {
    affected_rows
    returning {
      step_id
    }
  }
}
`

//Types
type Accordian = {
    activeIndex: number
}

type Step = {
    step_id: string,
    sequence: number,
    name: string,
    type: string,
    action_type: string,
    config: string
  }
  
const stepTypes = [
    { key: 'nav', text: 'NAV', value: 'NAV' },
    { key: 'test', text: 'TEST', value: 'TEST' },
]

const navActions = [
    { key: 'click', text: 'Click', value: 'CLICK' },
    { key: 'click_nw', text: 'Click no Waiting', value: 'NO_WAIT_CLICK' },
    { key: 'amp_click', text: 'AMP Click', value: 'AMP_CLICK' },
    { key: 'goto', text: 'Go Directly to URL', value: 'GOTO' },
    { key: 'wait', text: 'Wait', value: 'WAIT' },
    { key: 'reload', text: 'Reload', value: 'RELOAD' }
]

const testActions = [
    { key: 'function', text: 'Sonar Test Function', value: 'FUNCTION' },
    { key: 'nrx', text: 'Network Request Regex', value: 'NET_REGEX' },
    { key: 'nrxpd', text: 'Network Post Data Regex', value: 'NET_REGEX_POSTDATA' },
    { key: 'nrxxhrpd', text: 'Network XHR Data Regex', value: 'NET_REGEX_XHR_POSTDATA' },
    { key: 'custom', text: 'Custom Javascript', value: 'CUSTOM_JS' }
]

interface stepProps {
    step_id: string,
    index: number
}

interface confirmDialog {
    open: boolean,
    result: string
}



const StepForm: FC<stepProps> = ({step_id, index}) => {
    const [ accordianState, setAccordianState ] = useState<Accordian>({
        activeIndex: -1
    });

    const [ confirmState, setConfirmState ] = useState<confirmDialog>({
        open: false,
        result: ""
    });


    //Setup Test State
    const [ stepState, setStepState ] = useState<Step>();

    //Get Test Apollo Query
    const { loading: queryLoading, error: queryError, data } = useQuery(
        GET_STEPS, 
        {
            variables: { step_id: step_id },
        }
    );

    //Update Step
    const [
        updateStep,
        { loading: mutationLoading, error: mutationError }
    ] = useMutation(UPDATE_STEP);

    //Delete Step
    const [
        deleteStep,
        { loading: mutationDeleteLoading, error: mutationDeleteError }
    ] = useMutation(DELETE_STEP, {
        onCompleted: (data) => {
          if(data.delete_sonar_step.affected_rows){
            //Brute force for now :P
            window.location.reload();
          }
        }
      });

    if (queryLoading) return <p>Loading...</p>;
    if (queryError) return <p>Error :(</p>;

    const handleAccordianClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = accordianState
        const newIndex = activeIndex === index ? -1 : index
    
        setAccordianState({ activeIndex: newIndex })
    }

    const handleDelete = (e, value) => {
        const currentTime = new Date();
        deleteStep({variables: {step_id: stepState?.step_id }})
    }

    const handleCancel = (e, value) => {
        setConfirmState({ open: false, result: value })
    }


    const { activeIndex } = accordianState

    data.sonar_step.map((step: Step) => {
        if(!stepState){
            setStepState(step);
        }
    })

    if(!stepState){
        return <p>Loading...</p>;
    }

    const idPanel = [
        {
          key: 'step_id',
          title: 'Step ID',
          content: {
            as: Form.Input,
            value: stepState.step_id,
          },
        }
      ]

    return (
        <div>
            <Accordion.Title
                active={activeIndex === index}
                index={index}
                title={stepState.step_id}
                onClick={handleAccordianClick}
            >
                <Icon name='dropdown' />
                {stepState.name}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === index}>
                <Form onSubmit={e => {
                    let updated = new Date();
                    e.preventDefault();
                    updateStep({ variables: {
                        step_id: stepState.step_id,
                        sequence: parseInt(stepState.sequence, 10),
                        name: stepState.name,
                        type: stepState.type,
                        action_type: stepState.action_type,
                        config: stepState.config,
                        updated: updated
                    }});
                }}>
                <Form.Group widths='equal'>
                    <Form.Field
                        id='form-input-control-test-step-name'
                        control={Input}
                        value={stepState.name}
                        label='Step Name'
                        placeholder='Step name'
                        onChange={(e, { value }) => setStepState({...stepState, name: value })}
                    />
                    <Form.Field
                        id='form-input-control-test-step-sequence'
                        control={Input}
                        width='five'
                        label='Step Sequence'
                        value={stepState.sequence}
                        onChange={(e, { value }) => setStepState({...stepState, sequence: value })}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        id='form-input-control-test-step-type'
                        control={Select}
                        label='Step Type'
                        value={stepState.type}
                        options={stepTypes}
                        onChange={(e, { value }) => setStepState({...stepState, type: value })}
                    />
                    <Form.Field
                        id='form-input-control-test-step-action-type'
                        control={Select}
                        label='Action Type'
                        value={stepState.action_type}
                        options={stepState.type == "NAV" ? navActions : testActions}
                        onChange={(e, { value }) => setStepState({...stepState, action_type: value })}
                    />
                </Form.Group>
                <Editor
                    id='form-textarea-control-test-step-config'
                    value={deepParseJson(stepState.config)}
                    mode='code'
                    ace={ace}
                    theme="ace/theme/solarized_dark"
                    onChange={(json) => {
                        if(json){
                            setStepState( {
                                step_id: stepState.step_id,
                                name: stepState.name,
                                type: stepState.type,
                                action_type: stepState.action_type,
                                sequence: stepState.sequence,
                                config: JSON.stringify(json)   
                            })
                        }
                    } }
                />
                <Accordion panels={idPanel} />
                <p></p>
                <Button primary type='submit'>Save Step</Button>
                <Button color='red' onClick={ (e, value) => {setConfirmState({...confirmState, open: true})}} >Delete Step</Button>
                <Confirm
                    open={confirmState.open}
                    content='Permanently delete this Step?'
                    onCancel={handleCancel}
                    onConfirm={handleDelete}
                    negative
                />
                {mutationLoading && <p>Loading...</p>}
                {mutationError && <p>Error :( Please try again</p>}
                {mutationDeleteLoading && <p>Loading...</p>}
                {mutationDeleteError && <p>Error :( Please try again</p>}
                </Form>
            </Accordion.Content>
        </div>
    )
}

export default StepForm;