import React, { useState } from 'react';
import * as jwt from 'jsonwebtoken';
import { Card, Container, Header, Image } from 'semantic-ui-react';
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import LoadingSegment from '../../Components/LoadingSegment';
import ErrorSegment from '../../Components/ErrorSegment';
import { Helmet } from 'react-helmet';
import CompassLogoGreen from '../../Images/compass_logo_green.svg';
import FolderIcon from '../../Images/Compass/folder_icon.svg';
import { useHistory, useParams } from 'react-router-dom';
interface State {
	active: string | null
}

const GET_METABASE_DASHBOARDS = gql`
query MyQuery {
	compass_client_folder {
	  folder_id
	  name
	  metabase_dashboards {
		dashboard_id
		favorite
		name
	  }
	}
  }
  
`;

const Reports = () => {
	const [ state, setState ] = useState<State>( {
		active: null,
	} );
	const history = useHistory();
	const { loading, error, data, refetch } = useQuery( GET_METABASE_DASHBOARDS );
	if ( loading ) {
		return <LoadingSegment />;
	} else if ( error ) {
		return <ErrorSegment message={ JSON.stringify( error ) } />;
	}
	if ( data.compass_client_folder.length === 0 ) {
		return <ErrorSegment message={ 'No Dashboards Available' } />;
	}
	if ( data ) {
		const favorites = [];
		const folders = [];
		for ( const folder of data.compass_client_folder ) {
			const { folder_id, name } = folder;
			folders.push( { folder_id, name } );
			for ( const dashboard of folder.metabase_dashboards ) {
				if ( dashboard.favorite ) {
					favorites.push( dashboard );
				}
			}
		}
		console.log( favorites );
		console.log( folders );
		return (
			<div className="compass">
				<Helmet>
					<title>Compass</title>
				</Helmet>
				<div className="header-strip" style={ { display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', width: '100%', height: '350px', overflow: "hidden", textAlign: 'center', position: 'relative' } }>
					<Header as="h1" >Compass
						<Header.Subheader>Achieve clarity and insight for marketing performance
							reporting, analysis and decision-making.</Header.Subheader>
					</Header>
					<Image src={ CompassLogoGreen } style={ { position: 'absolute', right: '10px', bottom: '-120px' } } />
				</div>
				<Container style={ { padding: "25px 0 0 0" } }>
					<Header as="h3">Favorites</Header>
					<div className="card-container">
						{ favorites.map( ( item )=> (
							<div className="nav-card" key={ item.dashboard_id } onClick={ ()=>history.push( `/compass/dashboard/${ item.dashboard_id }` ) }>
								<img src={ CompassLogoGreen } />
								<div className="card-name">
									{ item.name }
								</div>
							</div>
						) ) }
					</div>
				
					<Header as="h3" style={ { marginTop: '50px' } }>Folders</Header>
					<div className="card-container">
						{ folders.map( ( item )=> (
							<div className="nav-card" key={ item.folder_id } onClick={ ()=>history.push( `/compass/folder/${ item.folder_id }` ) }>
								<img src={ FolderIcon } />
								<div className="card-name">
									{ item.name }
								</div>
							</div>
						) ) }
					</div>
				</Container>
			</div>
		);
	} 
	return (
		<div>
			<h1>This is the Dashboard View (missing vars for metabase demo)</h1>
		</div>
	);
};

export default Reports;
