// TODO- MOVE TO SERVER SIDE
import { getAccessToken } from "../../ClientManager/GoogleAnalytics";
import Axios from 'axios';
export const getMCF = async( accountId, propertyId, viewId, converstionType, startDate, endDate, goalNumber? ) => {
	const tok = await getAccessToken();
	let uri;
	if ( converstionType === 'transaction' ) {
		uri = `https://www.googleapis.com/analytics/v3/data/mcf?ids=${ `ga:${ viewId }` }&metrics=${ "mcf:totalConversions,mcf:totalConversionValue" }&start-date=${ startDate }&end-date=${ endDate }&dimensions=${ "mcf:sourceMediumPath,mcf:campaignPath,mcf:conversionGoalNumber,mcf:conversionType" }${ true ? `&filters=mcf:conversionType%3D%3DTransaction` : '' }`;
	} else if ( converstionType === 'goal' && goalNumber ) {
		uri = `https://www.googleapis.com/analytics/v3/data/mcf?ids=${ `ga:${ viewId }` }&metrics=${ "mcf:totalConversions,mcf:totalConversionValue" }&start-date=${ startDate }&end-date=${ endDate }&dimensions=${ "mcf:sourceMediumPath,mcf:campaignPath,mcf:conversionGoalNumber,mcf:conversionType" }${ true ? `&filters=mcf:conversionGoalNumber%3D%3D${ pad( goalNumber, 3 ) }` : '' }`;
	}
	try {
		let mcf = await mcfHelper( uri, tok, 1000 );
		if ( mcf.rows ) {
			// if (mcf.containsSampledData) {
			//     ctx.throw(413, 'GA Data Sampled')
			// }
			if ( mcf.totalResults > mcf.itemsPerPage ) {
				mcf = await pagination( uri, tok, mcf.rows, mcf.totalResults, 10000 );
			} else {
				mcf = mcf.rows;
			}
			// Write to CSV
			const parsedMcf = parse_MCF( mcf, 'default', 'default' );
			return parsedMcf;
		} 
		throw Error( "Insufficient Data" );
	} catch ( e ) {
		console.log( e );
		throw e;
	}
};
const pad = ( n, width, z? ) => {
	z = z || '0';
	n = n + '';
	return n.length >= width ? n : new Array( width - n.length + 1 ).join( z ) + n;
};

const mcfHelper = async( uri, access_token, maxResults ) => {
	const url = uri + `&max-results=${ maxResults }`;
	// console.log( url );
	try {
		const response = await Axios.get( url, {
			headers: {
				Authorization: `Bearer ${ access_token }`,
				'Content-Encoding': 'gzip',
				'User-Agent': 'axios (gzip)',
			},
		} );
		return response.data;
	} catch ( err ) {
		console.log( err );
		if ( err.response.data.error.errors[ 0 ].reason === 'responseTooLarge' && maxResults > 1000 ) {
			console.log( 'Backing off on response size' );
			return mcfHelper( uri, access_token, Math.floor( maxResults / 2 ) );
		} 
		throw err;
	}
};

const pagination = async( uri, access_token, arr, total, maxResults, backoff? ) => {
	const url = uri + `&max-results=${ maxResults }`;
	backoff = backoff || 1;
	// console.log( arr.length, total );
	if ( arr.length < total ) {
		try {
			const response = await Axios.get( url + `&start-index=${ arr.length + 1 }`, {
				headers: {
					Authorization: `Bearer ${ access_token }`,
				},
			} );
			const newarr = response.data.rows;
			const a = arr.concat( newarr );
			return pagination( uri, access_token, a, total, 10000 );
		} catch ( err ) {
			if ( err.response.data.error.errors[ 0 ].reason === 'responseTooLarge' && maxResults > 1000 ) {
				console.log( 'Backing off on response size' );
				return pagination( uri, access_token, arr, total, Math.floor( maxResults / 2 ) );
			}
			const x = backoff + 1;
			console.log( 'Waiting ' + ( 1000 * ( backoff ^ 2 ) + ( Math.random() + 1 ) ) + ' ms and retrying' );
			if ( backoff < 3 ) {
				setTimeout( function() {
					return pagination( uri, access_token, arr, total, 10000, x );
				}, ( 1000 * ( backoff ** 2 ) + ( Math.random() + 1 ) ) );
			} else {
				throw err;
			}
		}
	} else {
		return arr;
	}
};

function parse_MCF( mcf, cgp, dm ) {
	const output = [];
	mcf.map( ( row ) => {
		const output_row = [];
		let steps = '';
		row[ 0 ].conversionPathValue.map( ( step, i ) => {
			let action = classifyNode( step.nodeValue, cgp );
			if ( action === 'Media' && cgp.match( 'ecOnly' ) ) {
				if ( row[ 1 ].conversionPathValue[ i ].nodeValue.match( /(ec2017)|(ec2018)|(17_EXPC_EXPRESSCARE_MOBILE)|(17_EXC_F_MD17-1009_SAU_EVERETT EXPRESS CARE)|(18_EXC_F_MK18-6022_PRO_EXPRESS CARE UMBRELLA)|(17_EXPC_UMBRELLA_SOCIAL)|(17_EXPC_EXPRESS CARE UMBRELLA_PROG D)|(17_EXPC_EXPRESSCARE_Q1_PROG D)/g ) ) {
					action = 'Media';
				} else {
					action = 'Non EC Media';
				}
			}
			if ( dm === 'unknown' ) {
				if ( action === 'Direct' ) {
					if ( i === row[ 0 ].conversionPathValue.length - 1 && i === 0 ) {
						const push = i !== row[ 0 ].conversionPathValue.length - 1 ? `${ 'Unknown' } > ` : 'Unknown';
						steps += push;
					} else {
						const currPath = steps.split( ' > ' );
						if ( currPath[ currPath.length - 2 ] !== undefined ) {
							const push = i !== row[ 0 ].conversionPathValue.length - 1 ? `${ currPath[ currPath.length - 2 ] } > ` : currPath[ currPath.length - 2 ];
							if ( push === 'undefined' ) {
								console.log( 'break' );
							}
							steps += push;
						}
					}
				} else {
					const push = i !== row[ 0 ].conversionPathValue.length - 1 ? `${ action } > ` : action;
					steps += push;
				}
			} else if ( dm === 'default' ) {
				const push = i !== row[ 0 ].conversionPathValue.length - 1 ? `${ action } > ` : action;
				steps += push;
			} else if ( dm === 'first' ) {
				if ( action === 'Direct' && true ) {
					if ( i === 0 && true ) {
						const push = i !== row[ 0 ].conversionPathValue.length - 1 ? `${ action } > ` : action;
						steps += push;
					} else {
						const currPath = steps.split( ' > ' );
						if ( currPath[ 0 ] !== undefined ) {
							const push = i !== row[ 0 ].conversionPathValue.length - 1 ? `${ currPath[ 0 ] } > ` : currPath[ 0 ];
							if ( push === 'undefined' ) {
								console.log( 'break' );
							}
							steps += push;
						}
					}
				} else {
					const currPath = steps.split( ' > ' );
					if ( currPath[ currPath.length - 2 ] !== undefined ) {
						const push = i !== row[ 0 ].conversionPathValue.length - 1 ? `${ currPath[ currPath.length - 2 ] } > ` : currPath[ currPath.length - 2 ];
						if ( push === 'undefined' ) {
							console.log( 'break' );
						}
						steps += push;
					}
				}
			}
		} );
		if ( steps.slice( -3 ) === ' > ' ) {
			steps = steps.slice( 0, -3 );
		}
		if ( steps.length !== 0 ) {
			output_row.push( steps, parseInt( row[ 2 ].primitiveValue ), row[ 3 ].primitiveValue, parseInt( row[ 4 ].primitiveValue ), parseFloat( row[ 5 ].primitiveValue ) );
			output.push( output_row );
		}
	} );
	const final = array_to_csv( output );
	return { json: mcf, csv: final };
}

function classifyNode( node, grouping ) {
	// console.log( grouping );
	const source = node.split( ' / ' )[ 0 ];
	const medium = node.split( ' / ' )[ 1 ];
	if ( grouping === 'default' ) {
		switch ( true ) {
			case medium.match( 'organic' ) ? true : false :
				return 'Organic';
			case medium.match( /^(social|social-network|social-media|sm|social network|social media)$/g ) ? true : false:
				return 'Social';
			case ( source.match( 'direct' ) || medium.match( /^(none)|(not set)/g ) ) ? true : false:
				return 'Direct';
			case medium.match( /^(display|cpm|banner)$/g ) ? true : false :
				return 'Display';
			case medium.match( 'email' ) ? true : false :
				return 'Email';
			case medium.match( /^(cpv|cpa|cpp|content-text)$/g ) ? true : false :
				return 'Other Advertising';
			case medium.match( /^(cpc|ppc|paidsearch)$/g ) ? true : false :
				return 'Paid Search';
			case medium.match( 'referral' ) ? true : false :
				return 'Referral';
			case medium.match( 'affiliate' ) ? true : false :
				return 'Affiliate';
			default:
				return '(Other)';
		}
	} else if ( grouping === 'providence' || grouping === 'providence ecOnly' ) {
		switch ( true ) {
			case medium.match( 'organic' ) ? true : false :
				return 'Organic';
			case medium.match( /^(social|social-network|social-media|sm|social network|social media|social_organic|social_paid|influencer)$/g ) ? true : false:
				return 'Social';
			case source.match( /(facebook|twitter)/g ) ? true : false:
				return 'Social';
			case ( source.match( 'direct' ) || medium.match( /^(none)/g ) ) ? true : false:
				return 'Direct';
			case medium.match( /^(display|cpm|banner|video_programmatic|video_premium_online|video_tv|audio_streaming|audio_terrestrial|print|out_of_home|other_paid|multi)$/g ) ? true : false :
				return 'Media';
			case medium.match( /^(display|cpm|banner|video_programmatic|video_premium_online|video_tv|audio_streaming|audio_terrestrial|print|out_of_home|other_paid|multi)$/g ) ? true : false :
				return 'Display';
			case medium.match( 'email' ) ? true : false :
				return 'Email';
			case medium.match( /^(cpv|cpa|cpp|content-text|sponsorship)$/g ) ? true : false :
				return 'Other Advertising';
			case medium.match( /^(cpc|ppc|paidsearch)$/g ) ? true : false :
				return 'Paid Search';
			case medium.match( 'referral' ) ? true : false :
				return 'Referral';
			default:
				return '(Other)';
		}
	} else if ( grouping === 'psd' ) {
		switch ( true ) {
			case node === 'additionaljobs / banner':
				return 'Practice Partners';
			case source === 'similar jobs widget-de':
				return '(Other)';
			case (
				source.match( /(entrylevel-de|veterans\.usnlx\.com|ehtr-dev2\.fa\.us2\.oraclecloud\.com|veterans\.dejobs\.org|caljobs\.ca\.gov|physiciancareer\.com-de|ziprecruiter|\.jobs rss feed-de|connect\.directemployers\.org|=-de|alaskaphysicianjobs\.net|familymedicinecareers\.com|frontend\.la-de|medecareers\.com|ngcareer\.com|practicematch\.com|telecommte-jobs\.com-de|usnlx\.com)/g )
                || source.match( /(directemployersfoundation\.atlassian\.net|beyond\.com directemployers-de|careercenter\.asco\.org|jobs\.myjobhelper\.com|healthjobsnationwide\.com-de|flexjobs\.com|flexjobs\.com-de|de\.jobsyn\.org|gaswork\.com|careers\.aan\.com|careeronestop\.org|crnacareers\.com|build\.usnlx\.com|nlx\.jobsyn\.org|symplicity-de|ziprecruiter|dejobs\.org)/g )
			) || (
				medium === 'nlx'
                && source.match( /.*(ziprecruiter).*/g )
			):
				return 'Non-Contract Job Boards';
			case medium.match( /^(display|cpm|banner|video_programmatic|video_premium_online|video_tv|audio_streaming|audio_terrestrial|print|out_of_home|other_paid|multi)$/g ):
				return 'Display';
			case source === '(direct)' && medium === '(none)':
				return 'Direct';
			case source.match( /.*(careerarc).*/g )
            || source.match( /.*(glassdoor).*/g )
            || node.match( /.*(facebook|twitter|pinterest|linkedin|t\.co).* \/ (cpc|ppc)/g ):
				return 'Paid Social';
			case ! source.match( /.*(facebook|twitter|pinterest|linkedin|t\.co).*/g )
            && medium.match( /(cpc|ppc)/g ):
				return 'Organic Social';
			case source.match( /.*(providence|swedish|kadlec|pacific|joseph|covenant|stjs|stjhs|psjhmedgroups|stjoehumboldt|stjoesonoma|thequeen|psjhealth).*/g ):
				return 'Practice Partners';
			case source.match( /.*(indeed).*/g ):
				return 'Indeed';
			case source.match( /.*(google|bing|yahoo|comcast|duckduckgo|baidu).*/g )
            && medium.match( /(cpc|ppc)/g ):
				return 'Paid Search';
			case source.match( /.*(avature).*/g )
            || source.match( /.*(mail|email|exacttarget|salesforce|outlook).*/g )
            || medium.match( /.*(mail|email|exacttarget|salesforce|outlook).*/g ):
				return 'Email';
			case source.match( /.*(google|bing|yahoo|comcast|duckduckgo|baidu).*/g )
            || medium === 'organic'
            || source.match( /.*(google_jobs_apply).*/g ):
				return 'Organic Search';
			case node.match( /.*(east.org|goeshow|aafp|asmbs|aad.org|jspargo).*/g )
            || source === 'event'
            || medium === 'event'
            || source === 'events'
            || medium === 'events'
            || source === 'washingtonacp.org'
            || source === 'psychiatry.org'
            || source === 'acponline.org':
				return 'Events';
			case node.match( /.*(Job Aggregator|Job Board|Job Aggregate|Other|practicelink|PracticeLink|practicematch|PracticeMatch|physemp|jobcenter|jobsyn|indeed|jobs\.|\.JOBS|Job Center|career|NLX|work|aaos|npa|aanp|aapa|directemployers|-DE).*/g )
            || source === 'yourmembership.atlassian.net'
            || source === 'nejm.org':
				return 'Contract Job Boards';
			case ( medium === 'referral'
                || medium === 'nlx' )
            && ! source.match( /.*(asana|basecamp).*/g ):
				return 'Non-Contract Job Boards';
			default:
				return '(Other)';
		}
	} else {
		throw ( 'Channel Grouping Not Set' );
	}
}

function array_to_csv( array ) {
	const pre_array = [];
	array.map( ( row, i ) => {
		const temp_row = row.join( ',' );
		pre_array.push( i === 0 ? 'path,goalNumber,type,conversions,value\n' + temp_row : temp_row );
	} );
	return pre_array.join( '\n' );
}
