// @ts-nocheck
import React, { FC, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
//import { useDispatch, useSelector } from 'react-redux';
//import { RootState } from '../../../Store';
import { Redirect } from "react-router-dom";
import ClientList from './Client/ClientList';

//
// is the index for the entire tool.
// maintains the header handler for navigation
// houses the three main components of the tool; Clients, Properties, Views
//

const GET_ACTIVE_CLIENT = gql`
query GetActiveClient {
	client_user {
	  active_client_id
	}
  }  
`;

const Sonar: FC = () => {
	const [client_id, setClientID] = useState("");
	const { loading: l, error: e, data: current, refetch } = useQuery( GET_ACTIVE_CLIENT, {
		fetchPolicy: "no-cache",
	} );

	if (l) {
        return null;
    }
	const redirectString = `/sonar/client/${current.client_user[ 0 ].active_client_id}`
	return (
		<div>
			<Redirect to={redirectString} />
			<div className="sub-heading" style={ { width: '100%', padding: '20px', borderBottom: '1px solid #ddd' } }>
			{ ! client_id ? <ClientList /> : null }
			</div>
		</div>
	);
};

export default Sonar;
