import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Card, Button, Grid, Header, Message } from 'semantic-ui-react'; 
import LoadingSegment from '../../Components/LoadingSegment';
import ErrorSegment from '../../Components/ErrorSegment';
import ModelSelection from './ModelBuilder';
import { useHistory, Switch, Route } from 'react-router-dom';
import Modeler from './Modeler';
import { Helmet } from 'react-helmet';
const GET_CLIENT_MODELS = gql`
query GetClientModels {
	sextant_client_model {
	  configuration
	  name
	  description
	  client_model_id
	}
  }
  
`;
type State = {
	showBuilder: boolean
}
const AttributionModeler: React.FC = () => {
	const [ state, setState ] = useState<State>( {
		showBuilder: false,
	} ); 
	const history = useHistory();
	const { loading, error, data, refetch } = useQuery( GET_CLIENT_MODELS );
	if ( loading ) {
		return <LoadingSegment />;
	} else if ( error ) {
		return <ErrorSegment message={ JSON.stringify( error ) } />;
	}
	return (
		<Grid container style={ { padding: '50px 0' } }>
			<Helmet>
				<title>Sextant</title>
			</Helmet>
			<Grid.Row>
				<Grid.Column>
					<Header as="h1">
						Sextant
						<Header.Subheader>
							Attribution modeling and science and math. It's basically the future.
						</Header.Subheader>
					</Header>
				</Grid.Column>
			</Grid.Row>

			<Switch>
				<Route path="/sextant/:modelId" component={ Modeler } />
				<Route path="*">
					{ state.showBuilder ? 
						<ModelSelection exit={ ()=>setState( { ...state, showBuilder: false } ) } />
						: <>
							<Grid.Row columns={ 2 }>
								<Grid.Column>
									<Header as="h2">My Models</Header>
								</Grid.Column>
								<Grid.Column floated="right" textAlign="right">
									<Button basic primary content="New Model" icon="plus" onClick={ ()=> setState( { ...state, showBuilder: true } ) } />
								</Grid.Column>
							</Grid.Row>
							<Grid.Row columns={ 1 }>
								<Grid.Column>
									<Card.Group centered itemsPerRow={ 4 }>
										{ data && data.sextant_client_model && data.sextant_client_model.length > 0 ? 
											data.sextant_client_model.map( model=>(
												<Card key={ model.client_model_id }>
													<Card.Content>
														<Card.Header>{ model.name }</Card.Header>
														{ model.configuration && model.configuration.conversionType ? <Card.Meta>{ model.configuration.conversionType }</Card.Meta> : null }
														{ model.description ? <Card.Description>{ model.description }</Card.Description> : null }												
													</Card.Content>
													<Card.Content extra>
														{ /* <div className="ui two buttons"> */ }
														<Button fluid primary onClick={ ()=> history.push( `/sextant/${ model.client_model_id }` ) }>
															Open
														</Button>
														{ /* <Button basic color="red">
																Delete
															</Button> */ }
														{ /* </div> */ }
													</Card.Content>
												</Card>	
											) )
											:
											<Message positive content="No saved models found, please create one by clicking 'New Model'!" /> }
									</Card.Group>
								</Grid.Column>
							</Grid.Row>
						</>
					}
				</Route>
			</Switch>
		</Grid>
	);
};

export default AttributionModeler;
