// @ts-nocheck
import React, { useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Form, List, Cascader, Button, Modal, Input } from 'antd';
import axios from 'axios';

const layout = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 8,
	},
};

const ADD_GOOGLE_ANALYTICS_ACCOUNT = gql`
mutation MyMutation($starred: Boolean = false, $name: String,  $ga_account_id: String, $client_id: uuid) {
    insert_google_analytics_account_one(object: {client_id: $client_id, ga_account_id: $ga_account_id, name: $name, starred: $starred}) {
      client_id
      created
      ga_account_id
      name
      starred
    }
  }  
`;

const ADD_GOOGLE_ANALYTICS_PROPERTY = gql`
mutation MyMutation($starred: Boolean = false, $name: String, $ga_property_id: String, $client_id: uuid,  $ga_account_id: String) {
    insert_google_analytics_property_one(object: {client_id: $client_id, ga_property_id: $ga_property_id, ga_account_id: $ga_account_id, name: $name, starred: $starred }) {
      client_id
      created
      ga_property_id
      name
      starred
    }
  }  
`;

const ADD_GOOGLE_ANALYTICS_VIEW = gql`
mutation MyMutation($starred: Boolean = false, $name: String, $ga_view_id: String, $client_id: uuid, $ga_property_id: String,  $ga_account_id: String) {
    insert_google_analytics_view_one(object: {client_id: $client_id, ga_view_id: $ga_view_id, ga_property_id: $ga_property_id, ga_account_id: $ga_account_id, name: $name, starred: $starred }) {
      client_id
      created
      ga_view_id
      ga_url
      name
      starred
    }
  }  
`;

const GET_GOOGLE_ANALYTICS = gql`
query MyQuery {
	google_analytics_account {
	  properties {
		views {
		  name
		  ga_view_id
		}
		ga_property_id
		name
	  }
	  name
	  ga_account_id
	}
  }
`;

function filter( inputValue, path ) {
	return path.some( option => option.label.toLowerCase().indexOf( inputValue.toLowerCase() ) > -1 );
}

export async function getAccessToken() {
	const params = new URLSearchParams();
	params.append( 'client_id', process.env.REACT_APP_GOOGLE_ANALYTICS_OAUTH_CREDENTIALS_CLIENT_ID );
	params.append( 'client_secret', process.env.REACT_APP_GOOGLE_ANALYTICS_OAUTH_CREDENTIALS_CLIENT_SECRET );
	params.append( 'refresh_token', process.env.REACT_APP_GOOGLE_ANALYTICS_OAUTH_CREDENTIALS_REFRESH_TOKEN );
	params.append( 'grant_type', 'refresh_token' );
	try {
		const resp = await axios.post( 'https://oauth2.googleapis.com/token', params, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		} );
		return resp.data.access_token;
	} catch ( e ) {
		console.log( e );
		return e;
	}
}

export async function getAnalyticsAccounts() {
	const access_token = await getAccessToken();
	const url = 'https://www.googleapis.com/analytics/v3/management/accountSummaries';
	const { data } = await axios.get( url, {
		headers: {
			Authorization: "Bearer " + access_token,
		},
	} );
	console.log( JSON.stringify( data.items ) );
	return data.items;
}

const GoogleAnalytics: React.FC = ( { client_id } ) => {
	const [ state, setState ] = useState<State>( {
		show: false,
		client_id: client_id,
	} );
	const { loading, error, data, refetch } = useQuery( GET_GOOGLE_ANALYTICS, { variables: { client_id: { _eq: parseInt( client_id ) } } } );
	const [ addAccount, { accountResp } ] = useMutation( ADD_GOOGLE_ANALYTICS_ACCOUNT );
	const [ addProperty, { propertyResp } ] = useMutation( ADD_GOOGLE_ANALYTICS_PROPERTY );
	const [ addView, { viewResp } ] = useMutation( ADD_GOOGLE_ANALYTICS_VIEW );

	const [ visible, setVisible ] = React.useState( false );
	const [ confirmLoading, setConfirmLoading ] = React.useState( false );

	const showModal = async() => {
		const accounts = await getAnalyticsAccounts();
		setState( { ...state,
			accounts: accounts, accountOptions: accounts.map( account => ( {
				value: account.id,
				label: `${ account.name } - ${ account.id }`,
			} ),
			),
		} );
		setVisible( true );
	};

	const handleOk = async() => {
		const acc = state.accounts.filter( account=> account.id === state.selectedAccount )[ 0 ];
		console.log( acc );
		setConfirmLoading( true );
		await addAccount( { variables: { starred: acc.starred ? true : false, name: acc.name, ga_account_id: acc.id, client_id: state.client_id } } );
		for ( const property of acc.webProperties ) {
			await addProperty( { variables: { starred: property.starred ? true : false, name: property.name, ga_account_id: acc.id, ga_property_id: property.id, client_id: state.client_id } } );
			for ( const view of property.profiles ) {
				await addView( { variables: { starred: view.starred ? true : false, name: view.name, ga_account_id: acc.id, ga_property_id: property.id, ga_view_id: view.id, client_id: state.client_id } } );
			}
		}
		await refetch();
		setVisible( false );
		setConfirmLoading( false );
	};

	const handleCancel = () => {
		setVisible( false );
	};

	function onChange( value, selectedOptions ) {
		// console.log( [ value, selectedOptions ] );
		setState( { ...state, selectedAccount: value[ 0 ] } );
	}

	if ( loading ) return <p>Loading...</p>;
	if ( error ) return <p>{ JSON.stringify( error ) }</p>;

	return (
		<>
			<div style={ { textAlign: 'center', width: '100%', marginTop: '25px' } }>
				<h2>Google Analytics</h2>
				<Button type="primary" onClick={ showModal } style={ { marginBottom: '25px' } }>
					Add Account
				</Button>
				<Modal
					title="Create New Client"
					visible={ visible }
					onOk={ handleOk }
					confirmLoading={ confirmLoading }
					onCancel={ handleCancel }
				>
					<Form>
						<Form.Item
							required={ true }
							label="Analytics Account"
							name="name"
						>
							<Cascader
								disabled={ ! state.accountOptions }
								options={ state.accountOptions }
								onChange={ onChange }
								placeholder="Please select"
								showSearch={ { filter } }
							/>
						</Form.Item>
					</Form>
				</Modal>

				{ data && data.google_analytics_account.length > 0 ?
					<>
						<h4>Accounts</h4>
						<ul>
							{ data.google_analytics_account.map( acc=> <li>{ `${ acc.name } - ${ acc.ga_account_id }` }</li> ) }
						</ul>
					</>
					: null }
			</div>

		</>
	);
};

export default GoogleAnalytics;
