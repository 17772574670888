/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const LoginGraphic = () => (
	<svg className="login-graphic">
		<defs>
			<style>
				{
					".a,.aa,.b,.p,.z{fill:none;}.b{stroke:#fff;opacity:0.244;}.b,.z{stroke-width:4px;stroke-dasharray:10;}.c,.w{fill:#007969;}.c{stroke:#ffb500;}.d,.t{fill:#fff;}.e{fill:#0077b7;}.f{fill:#2196f3;}.g{fill:#1e88e5;}.h{fill:#f44336;}.i{fill:#4caf50;}.j{fill:#ffc107;}.k{fill:#1877f2;}.l{fill:#ffba00;}.m{clip-path:url(#a);}.n{fill:url(#b);}.o{clip-path:url(#c);}.p{stroke:rgba(74,74,74,0.2);}.p,.q,.r,.s,.t{stroke-miterlimit:10;}.q{fill:rgba(0,121,105,0.5);stroke:#007969;}.r{fill:rgba(67,66,93,0.55);stroke:#43425d;}.s{fill:rgba(240,242,248,0.77);}.s,.t{stroke:#f0f2f8;}.u{fill:#4d4f5c;font-size:16px;font-family:Runda-Medium, Runda;font-weight:500;}.v{fill:#43425d;}.x{fill:#f0f2f8;}.y{font-size:23px;font-family:Lato-Bold, Lato;font-weight:700;}.z{stroke:#3e9a8e;}.aa{stroke:#e3002b;stroke-width:10px;}.ab{stroke:none;}.ac{filter:url(#x);}.ad{filter:url(#v);}.ae{filter:url(#t);}.af{filter:url(#r);}.ag{filter:url(#p);}.ah{filter:url(#n);}.ai{filter:url(#l);}.aj{filter:url(#j);}.ak{filter:url(#h);}.al{filter:url(#f);}.am{filter:url(#d);}"
				}
			</style>
			<clipPath id="a">
				<path
					className="a"
					d="M3.4,3.616C-.683,7.853.152,12.354.152,26.027c0,11.355-1.981,22.738,8.388,25.418,1.605.413,9.466.621,17.378.624h.344c7.934,0,15.83-.212,17.437-.628,4.317-1.114,7.83-4.616,8.31-10.721.033-.413.049-7.13.05-14.039v-.954c0-7.028-.018-13.965-.052-14.393C51.5,4.829,47.492,1.081,42.217.322,41.008.147,40.766.095,34.562.084c-3.368,0-6.333-.02-8.956-.041C23.08.022,20.869,0,18.925,0,9.7,0,6.432.468,3.4,3.616"
					transform="translate(0 -0.001)"
				/>
			</clipPath>
			<linearGradient
				id="b"
				x1={ 0.5 }
				x2={ 0.5 }
				y2={ 1 }
				gradientUnits="objectBoundingBox"
			>
				<stop offset={ 0 } stopColor="#5053c0" />
				<stop offset={ 0.227 } stopColor="#6b4dba" />
				<stop offset={ 0.374 } stopColor="#cb39a4" />
				<stop offset={ 0.547 } stopColor="#ff543e" />
				<stop offset={ 0.719 } stopColor="#ff7543" />
				<stop offset={ 1 } stopColor="#ffd953" />
			</linearGradient>
			<clipPath id="c">
				<rect className="a" width={ 52.058 } height={ 52.068 } />
			</clipPath>
			<filter
				id="d"
				x={ 160.222 }
				y={ 551 }
				width={ 506 }
				height={ 365 }
				filterUnits="userSpaceOnUse"
			>
				<feOffset dy={ 2 } />
				<feGaussianBlur stdDeviation={ 1 } result="e" />
				<feFlood floodOpacity={ 0.102 } />
				<feComposite operator="in" in2="e" />
				<feComposite in="SourceGraphic" />
			</filter>
			<filter
				id="f"
				x={ 408.305 }
				y={ 628.378 }
				width={ 228.9 }
				height={ 201.339 }
				filterUnits="userSpaceOnUse"
			>
				<feOffset dy={ 10 } />
				<feGaussianBlur stdDeviation={ 5 } result="g" />
				<feFlood floodOpacity={ 0.161 } />
				<feComposite operator="in" in2="g" />
				<feComposite in="SourceGraphic" />
			</filter>
			<filter
				id="h"
				x={ 421.758 }
				y={ 647.224 }
				width={ 189.702 }
				height={ 205.364 }
				filterUnits="userSpaceOnUse"
			>
				<feOffset dy={ 10 } />
				<feGaussianBlur stdDeviation={ 5 } result="i" />
				<feFlood floodOpacity={ 0.161 } />
				<feComposite operator="in" in2="i" />
				<feComposite in="SourceGraphic" />
			</filter>
			<filter
				id="j"
				x={ 442.001 }
				y={ 648.327 }
				width={ 172.992 }
				height={ 165.415 }
				filterUnits="userSpaceOnUse"
			>
				<feOffset dy={ 10 } />
				<feGaussianBlur stdDeviation={ 5 } result="k" />
				<feFlood floodOpacity={ 0.161 } />
				<feComposite operator="in" in2="k" />
				<feComposite in="SourceGraphic" />
			</filter>
			<filter
				id="l"
				x={ 441.186 }
				y={ 646.961 }
				width={ 157.137 }
				height={ 160.144 }
				filterUnits="userSpaceOnUse"
			>
				<feOffset dy={ 10 } />
				<feGaussianBlur stdDeviation={ 5 } result="m" />
				<feFlood floodOpacity={ 0.161 } />
				<feComposite operator="in" in2="m" />
				<feComposite in="SourceGraphic" />
			</filter>
			<filter
				id="n"
				x={ 194.708 }
				y={ 714.772 }
				width={ 42.535 }
				height={ 31.631 }
				filterUnits="userSpaceOnUse"
			>
				<feOffset dy={ 3 } />
				<feGaussianBlur stdDeviation={ 3 } result="o" />
				<feFlood floodOpacity={ 0.161 } />
				<feComposite operator="in" in2="o" />
				<feComposite in="SourceGraphic" />
			</filter>
			<filter
				id="p"
				x={ 195.62 }
				y={ 675.188 }
				width={ 42.535 }
				height={ 31.631 }
				filterUnits="userSpaceOnUse"
			>
				<feOffset dy={ 3 } />
				<feGaussianBlur stdDeviation={ 3 } result="q" />
				<feFlood floodOpacity={ 0.161 } />
				<feComposite operator="in" in2="q" />
				<feComposite in="SourceGraphic" />
			</filter>
			<filter
				id="r"
				x={ 195.62 }
				y={ 754.356 }
				width={ 42.535 }
				height={ 31.631 }
				filterUnits="userSpaceOnUse"
			>
				<feOffset dy={ 3 } />
				<feGaussianBlur stdDeviation={ 3 } result="s" />
				<feFlood floodOpacity={ 0.161 } />
				<feComposite operator="in" in2="s" />
				<feComposite in="SourceGraphic" />
			</filter>
			<filter
				id="t"
				x={ 195.62 }
				y={ 793.753 }
				width={ 42.535 }
				height={ 31.631 }
				filterUnits="userSpaceOnUse"
			>
				<feOffset dy={ 3 } />
				<feGaussianBlur stdDeviation={ 3 } result="u" />
				<feFlood floodOpacity={ 0.161 } />
				<feComposite operator="in" in2="u" />
				<feComposite in="SourceGraphic" />
			</filter>
			<filter
				id="v"
				x={ 152.387 }
				y={ 525.2 }
				width={ 120.332 }
				height={ 120.332 }
				filterUnits="userSpaceOnUse"
			>
				<feOffset dy={ 10 } />
				<feGaussianBlur stdDeviation={ 10 } result="w" />
				<feFlood floodOpacity={ 0.161 } />
				<feComposite operator="in" in2="w" />
				<feComposite in="SourceGraphic" />
			</filter>
			<filter
				id="x"
				x={ 152.387 }
				y={ 525.2 }
				width={ 120.332 }
				height={ 120.332 }
				filterUnits="userSpaceOnUse"
			>
				<feOffset dy={ 10 } />
				<feGaussianBlur stdDeviation={ 10 } result="y" />
				<feFlood floodOpacity={ 0.161 } />
				<feComposite operator="in" in2="y" />
				<feComposite in="SourceGraphic" />
			</filter>
		</defs>
		<g transform="translate(22.222 -819)">
			<path
				className="b"
				d="M583.956,1417.642c-37.8,105.669-230.195,64.69-339.719,68.48S77.711,1492.87,55.854,1508.2s-82.649,81.3-34.6,181.672,93.032,108.876,181.228,110.753c93.926,2,145.382-62.1,174.088-138.971s72.691-103.384,136.158-117.529a150.947,150.947,0,0,1,78.99,3.139c31.283,9.74,59.928,29.534,85.018,55.629,44.645,46.432,52.026,179.878,58.684,184.018"
				transform="translate(714.256 2666.676) rotate(180)"
			/>
			<g transform="translate(3 9)">
				<g className="c" transform="translate(650 1012)">
					<circle className="ab" cx={ 50 } cy={ 50 } r={ 50 } />
					<circle className="a" cx={ 50 } cy={ 50 } r={ 49.5 } />
				</g>
				<g className="c" transform="translate(257 987)">
					<circle className="ab" cx={ 50 } cy={ 50 } r={ 50 } />
					<circle className="a" cx={ 50 } cy={ 50 } r={ 49.5 } />
				</g>
				<g className="c" transform="translate(490 810)">
					<circle className="ab" cx={ 50 } cy={ 50 } r={ 50 } />
					<circle className="a" cx={ 50 } cy={ 50 } r={ 49.5 } />
				</g>
				<g className="c" transform="translate(19 1032)">
					<circle className="ab" cx={ 50 } cy={ 50 } r={ 50 } />
					<circle className="a" cx={ 50 } cy={ 50 } r={ 49.5 } />
				</g>
				<g className="c" transform="translate(429 1120)">
					<circle className="ab" cx={ 50 } cy={ 50 } r={ 50 } />
					<circle className="a" cx={ 50 } cy={ 50 } r={ 49.5 } />
				</g>
			</g>
			<g transform="translate(-47 71)">
				<path
					className="d"
					d="M4602.54,453.7v41.283l42.129-.387V453.7Z"
					transform="translate(-4505 546)"
				/>
				<path
					className="e"
					d="M45.477,0H4.523A4.523,4.523,0,0,0,0,4.523V45.477A4.523,4.523,0,0,0,4.523,50H45.477A4.523,4.523,0,0,0,50,45.477V4.523A4.523,4.523,0,0,0,45.477,0Zm-30,43.173a1.316,1.316,0,0,1-1.316,1.316h-5.6a1.316,1.316,0,0,1-1.316-1.316V19.686A1.316,1.316,0,0,1,8.553,18.37h5.6a1.316,1.316,0,0,1,1.316,1.316ZM11.354,16.156a5.323,5.323,0,1,1,5.323-5.323A5.323,5.323,0,0,1,11.354,16.156Zm33.4,27.123a1.21,1.21,0,0,1-1.21,1.21H37.53a1.21,1.21,0,0,1-1.21-1.21V32.263c0-1.643.482-7.2-4.295-7.2-3.705,0-4.457,3.8-4.608,5.512V43.279a1.21,1.21,0,0,1-1.21,1.21H20.392a1.21,1.21,0,0,1-1.21-1.21V19.58a1.21,1.21,0,0,1,1.21-1.21h5.815a1.21,1.21,0,0,1,1.21,1.21v2.049c1.374-2.062,3.416-3.653,7.763-3.653,9.627,0,9.572,8.994,9.572,13.936V43.279Z"
					transform="translate(94 995)"
				/>
				<g transform="translate(331 949)">
					<path
						className="f"
						d="M52.137,21.415a.423.423,0,0,1,.423.423V26.28A26.279,26.279,0,1,1,44.554,7.393.424.424,0,0,1,44.561,8l-6.284,6.284a.422.422,0,0,1-.589.008A16.548,16.548,0,1,0,42.1,31.144H26.7a.423.423,0,0,1-.423-.423V21.838a.423.423,0,0,1,.423-.423H52.137Z"
						transform="translate(0 0)"
					/>
					<g transform="translate(24.692 21.415)">
						<path
							className="g"
							d="M268.4,213.477v0Z"
							transform="translate(-240.531 -208.612)"
						/>
					</g>
					<path
						className="h"
						d="M39.793,19.349l-7.981-5.767A26.286,26.286,0,0,1,73.1,7.393.424.424,0,0,1,73.107,8l-6.284,6.284a.421.421,0,0,1-.588.009,16.553,16.553,0,0,0-26.442,5.058Z"
						transform="translate(-28.546 0)"
					/>
					<path
						className="i"
						d="M69.768,327.79a26.284,26.284,0,0,1-42.788-6.106l8.148-5.584a16.553,16.553,0,0,0,26.658,5.924Z"
						transform="translate(-24.21 -283.651)"
					/>
					<path
						className="j"
						d="M9.729,145a16.5,16.5,0,0,0,1.189,6.17L2.77,156.757a26.321,26.321,0,0,1,.5-24.451l7.981,5.767A16.482,16.482,0,0,0,9.729,145Z"
						transform="translate(0 -118.724)"
					/>
				</g>
				<g transform="translate(716 963.651)">
					<path
						className="d"
						d="M51.356,19.471l-17.009,26,14.5,34.262a32.921,32.921,0,0,0,4.959.5,30.385,30.385,0,0,0,5.921-.4L74.043,67.285,74.608,26.1l-7.366-6.627Z"
						transform="translate(-20.284 -11.293)"
					/>
					<path
						className="k"
						d="M34.32.349A34.3,34.3,0,0,0,28.6,68.475V41.83H20.317V32.241H28.6v-7.07c0-8.2,5.01-12.674,12.329-12.674a68.324,68.324,0,0,1,7.392.376v8.575l-5.076,0c-3.979,0-4.747,1.891-4.747,4.666v6.119h9.5l-1.238,9.588H38.5V68.7A34.3,34.3,0,0,0,34.32.349Z"
					/>
				</g>
				<path
					className="l"
					d="M57.326,14.94,63.45,29.021l8.357,3.345L40.12,49.968,53.635,36.143V5.649L39.746,0V50.351l14.063,8.584L87.155,39.014V25.208Z"
					transform="translate(468.254 1078)"
				/>
				<g transform="translate(564 771.508)">
					<g transform="translate(0 0)">
						<g className="m">
							<path
								className="n"
								d="M0,49.143,53.047,0,102.2,52.625,49.158,101.768Z"
								transform="translate(-4.43 -43.294) rotate(20)"
							/>
						</g>
					</g>
					<g className="o">
						<path
							className="d"
							d="M87.5,67.62c-7.853,0-15.311-.7-18.159,6.612-1.177,3.019-1.006,6.941-1.006,12.555,0,4.927-.158,9.558,1.006,12.553,2.842,7.315,10.36,6.614,18.155,6.614,7.52,0,15.274.783,18.157-6.614,1.179-3.05,1.006-6.912,1.006-12.553,0-7.488.413-12.322-3.218-15.951C99.768,67.159,94.8,67.62,87.5,67.62Zm-1.717,3.454c16.382-.026,18.467-1.847,17.316,23.452-.409,8.948-7.222,7.966-15.6,7.966-15.27,0-15.709-.437-15.709-15.713,0-15.454,1.211-15.7,13.989-15.709Zm11.948,3.182a2.3,2.3,0,1,0,2.3,2.3,2.3,2.3,0,0,0-2.3-2.3M87.5,76.944a9.842,9.842,0,1,0,9.839,9.843A9.843,9.843,0,0,0,87.5,76.944m0,3.454c8.446,0,8.457,12.778,0,12.778S79.048,80.4,87.5,80.4"
							transform="translate(-61.403 -60.748)"
						/>
					</g>
				</g>
			</g>
			<g transform="translate(-299 686)">
				<g className="am" transform="matrix(1, 0, 0, 1, 276.78, 133)">
					<rect
						className="d"
						width={ 500 }
						height={ 359 }
						transform="translate(163.22 552)"
					/>
				</g>
				<g transform="translate(672 735)">
					<line className="a" y1={ 125 } transform="translate(127.5 6.5)" />
					<line
						className="a"
						y1={ 88 }
						x2={ 88 }
						transform="translate(127.5 43.5)"
					/>
					<g transform="translate(-2.398 -40.284)">
						<g transform="translate(11.188 37.252)">
							<path
								className="p"
								d="M230.214,199.494V66.966L115.357.6.5,66.966V199.494L115.357,265.86Z"
								transform="translate(-0.5 -0.6)"
							/>
							<path
								className="p"
								d="M156.833,142.862V66.223L90.466,27.8,24.1,66.223v76.64L90.466,181.08Z"
								transform="translate(24.391 28.087)"
							/>
							<path
								className="p"
								d="M107.083,104.242V65.614L73.591,46.3,40.1,65.614v38.628l33.491,19.314Z"
								transform="translate(41.266 47.599)"
							/>
							<path
								className="p"
								d="M115.357.6V133.127L.5,66.966"
								transform="translate(-0.5 -0.6)"
							/>
							<path
								className="p"
								d="M.5,131.466,115.357,65.1V197.833"
								transform="translate(-0.5 67.427)"
							/>
							<path
								className="p"
								d="M171.257,165.427,56.4,99.061,171.257,32.9"
								transform="translate(58.457 33.466)"
							/>
							<g
								className="al"
								transform="matrix(1, 0, 0, 1, -404.01, -598.97)"
							>
								<path
									className="q"
									d="M180.114,155.977l7.207-45.763L197.1,48.122,94.322,9.719,27.956,69.045-.6,162.254l97.008,17.717Z"
									transform="translate(424.55 624.23)"
								/>
							</g>
							<g
								className="ak"
								transform="matrix(1, 0, 0, 1, -404.01, -598.97)"
							>
								<path
									className="r"
									d="M127.576,94.653l22.377-56.576L82.773,0,0,25.475,12.151,110.1l61.9,56.306Z"
									transform="translate(437.34 660.65) rotate(-3)"
								/>
							</g>
							<g
								className="aj"
								transform="matrix(1, 0, 0, 1, -404.01, -598.97)"
							>
								<path
									className="s"
									d="M114.446,47.673,98.29.753,48.452,0,0,26.284l24.217,58.4L83.68,102.309Z"
									transform="matrix(-0.95, -0.33, 0.33, -0.95, 565.89, 788.06)"
								/>
							</g>
							<g
								className="ai"
								transform="matrix(1, 0, 0, 1, -404.01, -598.97)"
							>
								<path
									className="t"
									d="M99.049,52.674,86.853,13.85,53.672,0,18.291,17.17,0,81.707,64.167,67.646Z"
									transform="matrix(-0.62, 0.79, -0.79, -0.62, 582.45, 703.23)"
								/>
							</g>
						</g>
					</g>
				</g>
				<g transform="translate(480.485 810.345)">
					<g transform="translate(0 40.545)">
						<text className="u" transform="translate(39.529 15)">
							<tspan x={ 0 } y={ 0 }>
								{ "Psychographics" }
							</tspan>
						</text>
						<g className="ah" transform="matrix(1, 0, 0, 1, -203.71, -717.89)">
							<rect
								className="v"
								width={ 24.535 }
								height={ 13.631 }
								rx={ 5 }
								transform="translate(203.71 720.77)"
							/>
						</g>
					</g>
					<g transform="translate(0.913)">
						<text className="u" transform="translate(39.529 15)">
							<tspan x={ 0 } y={ 0 }>
								{ "Demographics" }
							</tspan>
						</text>
						<g className="ag" transform="matrix(1, 0, 0, 1, -204.62, -677.34)">
							<rect
								className="w"
								width={ 24.535 }
								height={ 13.631 }
								rx={ 5 }
								transform="translate(204.62 681.19)"
							/>
						</g>
					</g>
					<g transform="translate(0.912 81.09)">
						<text className="u" transform="translate(39.529 15)">
							<tspan x={ 0 } y={ 0 }>
								{ "User Intent" }
							</tspan>
						</text>
						<g className="af" transform="matrix(1, 0, 0, 1, -204.62, -758.43)">
							<rect
								className="x"
								width={ 24.535 }
								height={ 13.631 }
								rx={ 5 }
								transform="translate(204.62 760.36)"
							/>
						</g>
					</g>
					<g transform="translate(0.912 119.526)">
						<text className="u" transform="translate(39.529 15)">
							<tspan x={ 0 } y={ 0 }>
								{ "Interests" }
							</tspan>
						</text>
						<g className="ae" transform="matrix(1, 0, 0, 1, -204.62, -796.87)">
							<path
								className="d"
								d="M4.8,0H19.731a4.8,4.8,0,0,1,4.8,4.8V8.826a4.8,4.8,0,0,1-4.8,4.8H4.8A4.8,4.8,0,0,1,0,8.826V4.8A4.8,4.8,0,0,1,4.8,0Z"
								transform="translate(204.62 799.75)"
							/>
						</g>
					</g>
				</g>
				<text className="y" transform="translate(652 748)">
					<tspan x={ -172.074 } y={ 0 }>
						{ "Customer Profile" }
					</tspan>
				</text>
			</g>
			<g transform="matrix(0.94, 0.342, -0.342, 0.94, 372.677, 1020.91)">
				<path
					className="z"
					d="M191.929,1035.206s-23.193.292-63.721-32.841c-22.271-18.207-35.711-65-36.3-77.985"
					transform="translate(-240.787 -620)"
				/>
				<g
					className="ad"
					transform="matrix(0.94, -0.34, 0.34, 0.94, -440.14, -54.67)"
				>
					<line
						className="aa"
						x2={ 40 }
						y2={ 40 }
						transform="matrix(0.94, 0.34, -0.34, 0.94, 200.6, 549.73)"
					/>
				</g>
				<g
					className="ac"
					transform="matrix(0.94, -0.34, 0.34, 0.94, -440.14, -54.67)"
				>
					<line
						className="aa"
						x1={ 40 }
						y2={ 40 }
						transform="matrix(0.94, 0.34, -0.34, 0.94, 200.6, 549.73)"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default LoginGraphic;
