import React, { useState } from 'react';
import { useRouteMatch, Switch, Route, useHistory, Redirect, Link } from 'react-router-dom';
import { Grid, List, Search, Header, Image, Segment, Button, Icon, Container } from 'semantic-ui-react';
import Dashboard from '../Views/Dashboard';
import AttributionModeler from '../Views/Sextant';
import Settings from '../Views/Settings';
import Support from '../Views/Support';
import Teams from '../Views/Teams';
import Sonar from '../Views/Sonar';
import Reports from '../Views/Compass';
import Sidebar from '../Components/Sidebar';
import ProfileMenu from '../Components/ProfileMenu';
import SmallLogo from '../Images/WheelhouseDMG_WLogo_RGB.png';
import CompassLogo from '../Images/WHN_Logos_Compass.svg';
import SonarLogo from '../Images/WHN_Logos_Sonar.svg';
import SextantLogo from '../Images/WHN_Logos_Sextant.svg';
import Client from '../Views/Sonar/Client/ViewClient';
import EditClientTest from '../Views/Sonar/Client/EditClientTest';
import ViewTest from '../Views/Sonar/Client/ViewTest';
import Metabase from '../Views/Compass/Metabase';
import Folders from '../Views/Compass/Folders';

export interface MenuItem {
		title: string,
		key: string,
		icon: string,
		disabled: boolean,
		primary: boolean,
		component: any,
		image?:any
}

type Props = {
	user: any
}

type State = {
	view: string;
	collapsed: boolean,
	hidden: boolean,
	menuItems: MenuItem[]
};

/**
 * 
 */
	
const Portal: React.FC<{user:any}> = ( { user } ) => {
	const [ state, setState ] = useState<State>( {
		view: 'Attribution Modeler',
		collapsed: true,
		hidden: false,
		menuItems: [
			{
				title: "Dashboard",
				key: "dashboard",
				icon: "chart line",
				disabled: false,
				component: <Dashboard />,
				primary: false,
			},
			{
				title: "Sextant",
				key: "sextant",
				icon: "clock outline",
				image: SextantLogo,
				disabled: false,
				component: <AttributionModeler />,
				primary: true,
			},
			{
				title: "Compass",
				key: "compass",
				icon: "compass",
				image: CompassLogo,
				disabled: false,
				component: <Reports />,
				primary: true,
			},
			{
				title: "Sonar",
				key: "sonar",
				icon: "circle outline",
				image: SonarLogo,
				disabled: false,
				component: <Sonar />,
				primary: true,
			},
			{
				title: "Teams",
				key: "teams",
				icon: "users",
				disabled: true,
				component: <Teams />,
				primary: false,
			},
			{
				title: "Settings",
				key: "settings",
				icon: "settings",
				disabled: false,
				component: <Settings />,
				primary: false,
			},
			{
				title: "Support",
				key: "support",
				icon: "book",
				disabled: false,
				component: <Support />,
				primary: false,
			},
		],
	} ); 

	const history = useHistory();
	const { path } = useRouteMatch();
	const { collapsed } = state;
	return (
		<Grid columns="equal" className="cover" id="app-header" style={ { height: '100%', padding: 0, margin: 0 } }>
			<Grid.Row style={ { padding: 0, margin: 0, height: '100%' } }>
				<Grid.Column style={ { boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)', height: '100%', margin: 0, minWidth: '100px', maxWidth: '100px', padding: 0, zIndex: 1000, display: state.hidden ? 'none' : null, position: 'fixed' } }>
					<div style={ { width: '100%', margin: '50px 0 0 0', textAlign: 'center' } } >
						<Image src={ SmallLogo } style={ { width: '40px', margin: '0 auto' } } />
					</div>
					<div className="portalNav">
						<List>
							{
								state.menuItems.filter( item=>item.primary ).map( ( view, i ) => 
									( <List.Item key={ i } className={ `navOption ${ window.location.pathname.includes( view.key ) ? ' active' : '' } ${ collapsed ? ' collapsed' : null }` } disabled={ view.disabled } onClick={ ()=>history.push( '/' + view.key ) }><Image src={ view.image } />{ collapsed ? '' : view.title }</List.Item> ),
								)
							}
						</List>
						<div style={ { position: 'absolute', bottom: '25px', left: '25px' } }>
							<ProfileMenu />
						</div>
					</div>
				</Grid.Column>
				{ /* <Sidebar menuItems={ state.menuItems } /> */ }
				<Grid.Column style={ { margin: '0 0 0 100px', padding: 0, height: '100%' } }>

					{ /* Child Component Routing */ }
					<Switch>
						<Route exact={ true } path="/sonar/client/:client_id/edit_test/:test_id" component={ EditClientTest } />
						<Route exact={ true } path="/sonar/client/:client_id/view_test/:test_id" component={ ViewTest } />
						<Route exact={ true } path="/sonar/client/:client_id" component={ Client } />
						<Route exact={ true } path="/compass/folder/:folder_id" component={ Folders } />
						<Route exact={ true } path="/compass/dashboard/:dashboard_id" component={ Metabase } />
						<Route exact={ true } path="/" component={ Dashboard } />
						{
							state.menuItems.map( route => <Route path={ path + route.key }>{ route.component }</Route> )
						}
						{ /* No Match route, handles 404s */ }
						<Route path="*">
							<Container style={ { marginTop: '50px' } }>
								<Segment placeholder>
									<Header icon>
										<Icon name="search" />
										We can't seem to find that page.
									</Header>
									<Segment.Inline>
										<Button primary onClick={ ()=>history.push( '/dashboard' ) }>Go Home</Button>
										<Button onClick={ ()=>history.goBack() }>Go Back</Button>
									</Segment.Inline>
								</Segment>
							</Container>
							{ /* TODO <NoMatch /> */ }
						</Route>
					</Switch>

				</Grid.Column>
			</Grid.Row>
			{ /* <Grid.Row>
				<Grid.Column width={ 16 }>
					Footer
				</Grid.Column>
			</Grid.Row> */ }
		</Grid>
	);
};

export default Portal;
