import React, { FC, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Grid, List, Segment, Icon, Accordion, Container, GridRow, Header, Menu, GridColumn, Form } from 'semantic-ui-react';
import { useParams, Link } from "react-router-dom";
import SyntaxHighlighter from 'react-syntax-highlighter';
import solarized_dark from 'react-syntax-highlighter/dist/esm/styles/hljs/solarized-dark';
import '../css/sonar.css';

const GET_CLIENT_TEST = gql`
query getClientTest($client_id: uuid!, $test_id: uuid!) {
  sonar_client_test(where: {client_id: {_eq: $client_id}, test_id: {_eq: $test_id}}) {
    client_id
    test {
      test_id
      name
      description
      enabled
      log_bucket
      log_path
      network_capture_config
      start_url
      test_status
      step_sequence
      last_executed
      steps {
        step_id
        name
        type
        action_type
        sequence
        config
        updated
      }
    }
  }
}
`;

type ClientTest = {
    test_id: string,
    name: string,
    description: string,
    enabled: boolean,
    network_capture_config: string,
    start_url: string,
    log_bucket: string,
    log_path: string,
    test_status: string,
    last_executed: string,
    steps: [
        {
            step: {
                step_id: string,
                sequence: number,
                name: string,
                type: string,
                action_type: string,
                config: string
                updated: string
            }
        }
    ]
}

type logData = {
    body: string
}

const ViewTest: FC = () => {
    let { client_id, test_id } = useParams();

    const { loading, error, data, refetch } = useQuery(GET_CLIENT_TEST, {
        variables: { client_id: client_id, test_id: test_id },
    });

    const [log, setLogState] = useState<logData>(
        {
          body: "Loading..."
        }
    )

    //Use Apollo's loading feature to prevent errors
    if (loading) {
        return null;
    }

    const test: ClientTest = data.sonar_client_test[0].test;

    if(test.log_path == null){
        return (
            <div>
                <Menu pointing secondary className="headerAttached" style={{ marginBottom: '50px' }}>
                    <Menu.Item name="Back to Test Flow List">
                        <Link to={`/sonar/client/${client_id}`}><Icon name='angle left'/><span>Back to Test Flow List</span></Link>
                    </Menu.Item>
                </Menu>
                <Segment placeholder>
                    <Header icon>
                    <Icon name='compass outline' />
                        Please run the Test at least once to see Test Details.
                    </Header>
                </Segment>
            </div>
        )
    }

    const renderTestLog = (log_name, log) => {
        const logLabel = log_name.match(/lake\/sonar\/logs\/(.*$)/)[1];
        //Simple Post to Endpoint to Invoke Test
        fetch(
            `https://v2q6ax6tb8.execute-api.us-west-2.amazonaws.com/v1/${logLabel}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/text'
                })
            }
            )
            .then(response => {
                response.text().then(function (text) {
                    setLogState({ body: text })
                    return text;
                })
            })
            .catch(error => console.log(error));
        return (
                <div>
                    <strong>Viewing: {log_name}</strong>
                    <SyntaxHighlighter language="haskell" style={solarized_dark}>
                    {log.body}
                    </SyntaxHighlighter>
                </div>
        )
    }

    return (
        <div>
            <Menu pointing secondary className="headerAttached" style={{ marginBottom: '50px' }}>
                <Menu.Item name="Back to Test Flow List">
                    <Link to={`/sonar/client/${client_id}`}><Icon name='angle left'/><span>Back to Test Flow List</span></Link>
                </Menu.Item>
            </Menu>
            <Grid>
                <GridColumn width={16}>
                    <GridRow>
                        <Container>
                            <Header style={{
                                fontFamily: 'Montserrat',
                                fontWeight: 600,
                                fontSize: '30px',
                                color: '#2d393b',
                                height: '65px'
                            }}>{test.name}</Header>
                            <p>{test.description}</p>
                            <List bulleted>
                                <List.Item><strong>Test Status:</strong> {test.test_status == "PASSING" && <span className="green-text">{test.test_status}</span>}{test.test_status == "FAILING" && <span className="red-text">{test.test_status}</span>}</List.Item>
                                <List.Item><strong>Last Executed:</strong> {test.last_executed}</List.Item>
                            </List>
                            { renderTestLog(test.log_path, log) }
                        </Container>
                    </GridRow>
                </GridColumn>
            </Grid>
        </div>
    )
}

export default ViewTest;