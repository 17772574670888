export const SET_THEME = 'SET_THEME';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_PROPERTY = 'SET_PROPERTY';
export const SET_PROPERTY_TAB = 'SET_PROPERTY_TAB';
export const SET_PROPERTY_TAB_ITEM = 'SET_PROPERTY_TAB_ITEM';
export const SET_PROPERTY_TAB_SUB_ITEM = 'SET_PROPERTY_TAB_SUB_ITEM';
export const SET_PROPERTY_VIEW = 'SET_PROPERTY_VIEW';
export const SET_PROPERTY_VIEW_TAB = 'SET_PROPERTY_VIEW_TAB';
export const SET_PROPERTY_VIEW_TAB_ITEM = 'SET_PROPERTY_VIEW_TAB_ITEM';
export const GET_CLIENT = 'GET_CLIENT';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';

export interface UserThemeAction {
    type: typeof SET_THEME;
    payload: string;
}

export interface UserClientAction {
    type: typeof SET_CLIENT;
    payload: string | null;
}

export interface UserPropertyAction {
    type: typeof SET_PROPERTY;
    payload: string | null;
}

export interface UserPropertyTabAction {
    type: typeof SET_PROPERTY_TAB;
    payload: string | null;
}

export interface UserPropertyTabItemAction {
    type: typeof SET_PROPERTY_TAB_ITEM;
    payload: string | null;
}

export interface UserPropertyTabSubItemAction {
    type: typeof SET_PROPERTY_TAB_SUB_ITEM;
    payload: string | null;
}

export interface UserPropertyViewAction {
    type: typeof SET_PROPERTY_VIEW;
    payload: string | null;
}

export interface UserPropertyViewTabAction {
    type: typeof SET_PROPERTY_VIEW_TAB;
    payload: string | null;
}

export interface UserPropertyViewTabItemAction {
    type: typeof SET_PROPERTY_VIEW_TAB_ITEM;
    payload: string | null;
}

export type UserAction = 
UserThemeAction 
| UserClientAction 
| UserPropertyAction 
| UserPropertyTabAction 
| UserPropertyViewAction 
| UserPropertyViewTabAction 
| UserPropertyViewTabItemAction
| UserPropertyTabItemAction 
| UserPropertyTabSubItemAction;

export interface UserState {
    theme: string,
    toolView: string | null,
    client: string | null,
    property: string | null,
    propertyTab: string | null,
    propertyTabItem: string | null,
    propertyTabSubItem: string | null,
    propertyView: string | null,
    propertyViewTab: string | null,
    propertyViewTabItem: string | null,
}

export interface Client {
    description: string;
    id: number,
    name: string,
    active: boolean,
    status: {
        missing: number,
        ok: number,
        needsUpdate: number
    },
    googleAnalytics: {
        accounts: [
            {
                accountId: string,
                properties: [
                    {
                        propertyId: string,
                        status: {
                            missing: number,
                            ok: number,
                            needsUpdate: number
                        },
                        name: string, // view name (example: www.positivepromotions.com)
                        notes: string,
                        currency: string, // view currency (example: USD)
                        eCommerceTracking: {
                            value:boolean,
                            status: string,
                            notes: string,
                            views: [ 
                                {
                                    viewId: string,
                                    status: string,
                                } 
                            ]
                        },
                        enhancedECommerceTracking: {
                            value:boolean,
                            status: string,
                            notes: string,
                            views: [ 
                                {
                                    viewId: string,
                                    status: string,
                                } 
                            ]
                        },
                        searchConsoleLinked: {
                            value:boolean,
                            status: string,
                            notes: string,
                            views: [ 
                                {
                                    viewId: string,
                                    status: string,
                                } 
                            ]
                        }, // 2
                        googleAdsLinked: {
                            value:boolean,
                            status: string,
                            notes: string,
                            views: [ 
                                {
                                    viewId: string,
                                    status: string,
                                } 
                            ]
                        }, // 1
                        customDimensions: [ // 4
                            {
                                value: {
                                    name: string,
                                    index: string,
                                    scope: string,
                                    active: boolean,
                                    dc: boolean
                                }
                                status: string,
                                notes: string,
                                views: [ 
                                    {
                                        viewId: string,
                                        status: string,
                                    } 
                                ]
                            },
                        ],
                        customMetrics: [ // 5
                            {
                                value: {
                                    name: string,
                                    index: number,
                                    scope: string,
                                    active: boolean,
                                    type: string,
                                    min_value: string,
                                    max_value: string,
                                }
                                status: string,
                                notes: string,
                                views: [ 
                                    {
                                        viewId: string,
                                        status: string,
                                    } 
                                ]
                            },
                        ],
                        filters: [
                            {
                                id: string,
                                name: string,
                                type: string,
                                created: string,
                                updated: string,
                                includeDetails: {
                                    value: {
                                        field: string,
                                        matchType: string,
                                        expressionValue: string,
                                        caseSensitive: boolean,
                                        fieldIndex: number
                                    }
                                    status: string,
                                    notes: string,
                                    views: [ 
                                        {
                                            name: string,
                                            status: string,
                                        } 
                                    ]
                                },
                                excludeDetails: {
                                    value: {
                                        field: string,
                                        matchType: string,
                                        expressionValue: string,
                                        caseSensitive: boolean,
                                        fieldIndex: number
                                    }
                                    status: string,
                                    notes: string,
                                    views: [ 
                                        {
                                            name: string,
                                            status: string,
                                        } 
                                    ]
 
                                },
                                lowercaseDetails: {
                                    value: {
                                        field: string,
                                        fieldIndex: number
                                    }
                                    status: string,
                                    notes: string,
                                    views: [ 
                                        {
                                            name: string,
                                            status: string,
                                        } 
                                    ]
                                },
                                uppercaseDetails: {
                                    value: {
                                        field: string,
                                        fieldIndex: number
                                    }
                                    status: string,
                                    notes: string,
                                    views: [ 
                                        {
                                            name: string,
                                            status: string,
                                        } 
                                    ]

                                },
                                searchAndReplaceDetails: {
                                    value: {
                                        field: string,
                                        fieldIndex: number,
                                        searchString: string,
                                        replaceString: string,
                                        caseSensitive: boolean
                                    }
                                    status: string,
                                    notes: string,
                                    views: [ 
                                        {
                                            name: string,
                                            status: string,
                                        } 
                                    ]
                                },
                                advancedDetails: [
                                    {
                                        value: {
                                            field: string,
                                            fieldIndex: number,
                                            extract: string,
                                            outputToField: string,
                                            outputToFieldIndex: number,
                                            outputConstructor: string,
                                            fieldRequired: boolean,
                                            overrideOutputField: boolean,
                                            caseSensitive: boolean
                                        }
                                        status: string,
                                        notes: string,
                                        id: string,
                                        views: [ 
                                            {
                                                name: string,
                                                status: string,
                                            } 
                                        ]
                                    }
                                ]
                            },
                        ],
                        goals: [
                            {
                                name: string,
                                value: string,
                                active: boolean,
                                type: string,
                                urlDestinationDetails: {
                                    url: string,
                                    caseSensitive: boolean,
                                    matchType: string,
                                    firstStepRequired: boolean,
                                    steps: [
                                        {
                                            number: number,
                                            name: string,
                                            url: string
                                        }
                                    ]
                                },
                                visitTimeOnSiteDetails: {
                                    comparisonType: string,
                                    comparisonValue: string
                                },
                                visitNumPagesDetails: {
                                    comparisonType: string,
                                    comparisonValue: string
                                },
                                eventDetails: {
                                    useEventValue: boolean,
                                    eventConditions: [
                                        {
                                            type: string,
                                            matchType: string,
                                            expression: string,
                                            comparisonType: string,
                                            comparisonValue: string
                                        }
                                    ]
                                }
                            },
                        ]
                        views: [
                            {
                                active: boolean,
                                name: string,
                                notes: string,
                                tag: string,
                                status: {
                                    missing: number,
                                    ok: number,
                                    needsUpdate: number
                                },
                                viewId: string,
                                defaultPage: {
                                    value: string,
                                    status: string,
                                    notes: string
                                }
                                botFilteringEnabled: {
                                    value: boolean,
                                    status: string,
                                    notes: string
                                }
                                excludeQueryParameters: {
                                    value: string,
                                    status: string,
                                    notes: string
                                }
                                siteSearchQueryParameters: {
                                    value: string,
                                    status: string,
                                    notes: string
                                }
                                stripSiteSearchQueryParameters: {
                                    value: boolean,
                                    status: string,
                                    notes: string
                                }
                                siteSearchCategoryParameters: {
                                    value: string,
                                    status: string,
                                    notes: string
                                }
                                stripSiteSearchCategoryParameters: {
                                    value: boolean,
                                    status: string,
                                    notes: string
                                }
                            }[],
                        ],
                    },
                ],
                active: boolean,
            },
        ]
    }
    integrityConfiguration: {
        tests: [
            {
                name: string,
                status: {
                    missing: number,
                    ok: number,
                    needsUpdate: number
                },
                note: string | null,
                enabled: boolean,
                updatedTimestamp: string,
                createdTimestamp: string,
                updatedBy: number //(FK -> Users),
                createdBy: number //(FK -> Users),
            }
        ]
        updatedBy: number //(FK -> Users),
        createdBy: number //(FK -> Users),
        updatedTimestamp: string,
        createdTimestamp: string
    } | null,
    updatedTimestamp: string,
    createdTimestamp: string
}

interface GetClientAction {
    type: typeof GET_CLIENT;
    payload: Client;
}

interface SetLoadingAction {
    type: typeof SET_LOADING;
}

interface SetErrorAction {
    type: typeof SET_ERROR;
    payload: string;
}
export interface ClientState {
    clients: Client[] | object;
    loading: boolean;
    error: string;
}

export type ClientAction = GetClientAction | SetErrorAction | SetLoadingAction;

