import React, { useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Card, Button, Grid, Header, Message, Form, Menu, Breadcrumb, Loader, Progress } from 'semantic-ui-react'; 
import LoadingSegment from '../../../Components/LoadingSegment';
import ErrorSegment from '../../../Components/ErrorSegment';
import { useHistory, useParams } from 'react-router-dom';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { getMCF } from '../ModelBuilder/Helper';
import ModelComparison from './ModelComparison';
import Axios from 'axios';

const GET_MODEL = gql`
query GetModel ($client_model_id: uuid!) {
    sextant_client_model_by_pk(client_model_id: $client_model_id) {
      configuration
      description
      name
    }
  }
`;

const Modeler: React.FC = ( ) => {
	const [ state, setState ] = useState<{
		activeTab: string,
		modelResult?: any,
        refineStartDate?: Date,
        refineEndDate?: Date,
        startDate?: Date,
		endDate?: Date,
		paths?: {
			json: string,
			csv: string
		}
        loadingStage?: {
            percent: number
            message: string
		}
    }>( {
    	activeTab: 'compare',
    } );
	const history = useHistory();
	const { modelId } = useParams();
	const { loading, error, data } = useQuery( GET_MODEL, { variables: { client_model_id: modelId } } );
	if ( loading ) {
		return <LoadingSegment />;
	} else if ( error ) {
		return <ErrorSegment message={ JSON.stringify( error ) } />;
	}
	let model;
	if ( data ) {
		model = data.sextant_client_model_by_pk;
	}
	const handleChange = ( e, data ) => setState( { ...state, [ data.name ]: data.value ? data.value : ! state[ data.name ] } );
	const handleCompareSubmit = async() => {
		setState( { ...state, loadingStage: { percent: 25, message: "Pulling Conversions" } } );
		const mcf = await getMCF( model.configuration.gaAccount, model.configuration.gaProperty, model.configuration.gaView, model.configuration.conversionType, state.startDate.toISOString().slice( 0, 10 ), state.endDate.toISOString().slice( 0, 10 ), model.configuration.goalNumber || null );
		if ( mcf && mcf.csv ) {
			setState( { ...state, loadingStage: { percent: 50, message: "Running Models" } } );
			const requestData = {
				order: 2,
				goal: model.configuration.goalNumber || 0,
				transactionGoal: model.configuration.conversionType === 'transaction',
				format: 'json',
				csv: mcf.csv,
			};
			const res = await Axios.post( 'https://j4akagkb66.execute-api.us-west-2.amazonaws.com/v0/sextant/model', {
				body: requestData,
			} );
			if ( res && res.status === 200 ) {
				setState( { ...state, loadingStage: { percent: 100, message: "Crunching Numbers" } } );
				await new Promise( resolve => setTimeout( resolve, 1000 ) );
				setState( { ...state, loadingStage: null, modelResult: JSON.parse( res.data.body ), paths: mcf } );
			}
			// const d = JSON.parse( JSON.parse( res.body ) );  Access-Control-Allow-Headers   Access-Control-Allow-Methods 
		}
	};

	const renderContent = () => {
		switch ( state.activeTab ) {
			case 'compare': {
				return ( <>
					<Grid.Row>
						<Grid.Column>
							<Form loading={ state.loadingStage ? true : false }>
								
								<Header as="h4">Test Settings
									<Header.Subheader>Choose the date range you would like to use to compare different model results.</Header.Subheader>
								</Header>
								<Form.Group>
									<SemanticDatepicker clearable={ false } label="Start Date" value={ state.startDate } filterDate={ ( date )=> date < new Date } name="startDate" onChange={ handleChange } />
									<SemanticDatepicker clearable={ false } label="End Date" value={ state.endDate } disabled={ state.startDate ? false : true } filterDate={ ( date )=>date > state.startDate && date < new Date } name="endDate" onChange={ handleChange } />
								</Form.Group>
								<Form.Button onClick={ handleCompareSubmit } disabled={ state.startDate && state.endDate ? false : true } primary content="Run Models" icon="paper plane" />
								
							</Form>
							{ state.loadingStage ? <> 
								<Header as="h4" style={ { width: '100%', textAlign: 'center' } }>{ state.loadingStage.message }</Header>
								<Progress indicating percent={ state.loadingStage.percent } /> 
							</> : null }
						</Grid.Column>
					</Grid.Row>
					{ state.modelResult ? <ModelComparison data={ state.modelResult } csv={ state.paths.csv } /> : null } </>
				);
			}
			case 'refine': {
				return (
					<>

					</>
				);
			}
			case 'report': {
				return (
					<Grid.Row>
						<Grid.Column>
							Report
						</Grid.Column>
					</Grid.Row>
				);
			}
			default: {
				return <ErrorSegment message="Default Fallthrough" />;
			}
		}
	};

	return (
		<>
			<Grid.Row columns={ 1 }>
				<Grid.Column>
					<Breadcrumb>
						<Breadcrumb.Section link onClick={ ()=>history.push( '/sextant' ) }>Sextant</Breadcrumb.Section>
						<Breadcrumb.Divider />
						<Breadcrumb.Section active> Model </Breadcrumb.Section>
					</Breadcrumb>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={ 2 }>
				<Grid.Column>
					<Header as="h2">
						{ model.name }
						{ model.description ? <Header.Subheader>{ model.description } </Header.Subheader> : null }
					</Header>
				</Grid.Column>
				<Grid.Column floated="right" textAlign="right">
					<Button.Group>
						<Button basic secondary content="Edit" icon="pencil" />
						<Button basic negative content="Delete" icon="delete" />
					</Button.Group>
				</Grid.Column>
			</Grid.Row>
			{ /* <Grid.Row> */ }
			{ /* <Grid.Column> */ }
			{ /* <Menu secondary> */ }
			{ /* <Menu.Item header>Stage</Menu.Item> */ }
			{ /* <Menu.Item active={ state.activeTab === 'compare' } onClick={ ()=>setState( { ...state, activeTab: 'compare' } ) }>Compare</Menu.Item> */ }
			{ /* <Menu.Item active={ state.activeTab === 'refine' } onClick={ ()=>setState( { ...state, activeTab: 'refine' } ) }>Refine</Menu.Item> */ }
			{ /* <Menu.Item active={ state.activeTab === 'report' } onClick={ ()=>setState( { ...state, activeTab: 'report' } ) }>Report</Menu.Item> */ }
			{ /* </Menu> */ }
			{ /* </Grid.Column> */ }
			{ /* </Grid.Row> */ }
			{
				renderContent()
			}
			
		</>
	);
};

export default Modeler;
