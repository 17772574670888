import React from 'react';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

const LoadingSegment = () => {
	return (
		<Dimmer active inverted>
			<Loader size="big" inverted content="Loading" />
		</Dimmer>
	);
};

export default LoadingSegment;
