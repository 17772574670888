import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import userReducer from './Reducers/userReducer';
import clientReducer from './Reducers/clientReducer';

const rootReducer = combineReducers( {
	clientData: clientReducer,
	user: userReducer,
} );

const store = createStore(
	rootReducer,
	composeWithDevTools( applyMiddleware( thunk ) ),
);

export type RootState = ReturnType<typeof rootReducer>;

export default store;
