import { Auth } from 'aws-amplify';

/**
 * helper method to validate  user token
 *
 * @param {*} token
 * @returns {boolean}
 */
export const getUserAuth = async( bypassCache: boolean ) => {
	try {
		const user = await Auth.currentAuthenticatedUser( { bypassCache: bypassCache } );
		if ( user ) {
			return user;
		}
	} catch ( e ) {
		return undefined;
	}
	return undefined;
};
