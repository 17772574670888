import React, { useState } from 'react';
import { Menu, Grid, Card, Image } from 'semantic-ui-react';
import HealthChart from './HealthChart';
import DataVolume from './DataVolume';
import CompassLogo from '../../Images/WHN_Logos_Compass.svg';
import SonarLogo from '../../Images/WHN_Logos_Sonar.svg';
import SextantLogo from '../../Images/WHN_Logos_Sextant.svg';

import { Link } from 'react-router-dom';
type State = {
    activeItem: 'summary' | 'services'
};

const renderSummary = () => {
	return (
		<Grid container id="userDashboard" stackable relaxed="very" style={ { marginTop: '29em' } }>
			<Grid.Row>
				<Grid.Column width={ 5 }>
					<Card fluid>
						<Card.Content>
							<Card.Header>Sonar</Card.Header>
							<Card.Description>
								<Image src={SonarLogo} width="60%"></Image>
							</Card.Description>
							<Card.Meta>Detect and defend against data integrity issues with 24/7 automated flow-based monitoring and anomaly detection.</Card.Meta>
						</Card.Content>
						<Card.Content extra style={ { backgroundColor: '#34AEC2' } }>
							<Link to="/sonar">
								Launch Sonar
							</Link>
						</Card.Content>
					</Card>
				</Grid.Column>
				<Grid.Column width={ 5 }>
					<Card fluid>
						<Card.Content>
							<Card.Header>Compass</Card.Header>
							<Card.Description>
								<Image src={CompassLogo} width="60%"></Image>
							</Card.Description>
							<Card.Meta>Achieve clarity and insight for marketing performance reporting, analysis and decision-making.</Card.Meta>
						</Card.Content>
						<Card.Content extra style={ { backgroundColor: '#007F71' } }>
							<Link to="/compass">
								Launch Compass
							</Link>
						</Card.Content>
					</Card>
				</Grid.Column>
				<Grid.Column width={ 5 }>
					<Card fluid>
						<Card.Content>
							<Card.Header>Sextant</Card.Header>
							<Card.Description>
								<Image src={SextantLogo} width="60%"></Image>
							</Card.Description>
							<Card.Meta>Superior attribution insights for critical marketing decisions. Attribution modeling and science and math. </Card.Meta>
						</Card.Content>
						<Card.Content extra style={ { backgroundColor: '#3DBEAF' } }>
							<Link to="/sextant">
								Launch Sextant
							</Link>
						</Card.Content>
					</Card>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				
				{/* <Grid.Column width={ 6 }>
					<Card fluid>
						<Card.Content>
							<Card.Header>Snowplow</Card.Header>
							<Card.Meta>One liner here that describes it</Card.Meta>
						</Card.Content>
						<Card.Content extra>
							<a>
								Launch
							</a>
						</Card.Content>
					</Card>
				</Grid.Column> */}
				{ /* <Grid.Column width={ 4 }>
					<Card fluid>
						<Card.Content>
							<Card.Header>Sextant</Card.Header>
						</Card.Content>
						<Card.Content extra>
							<a>
								Find Out More
							</a>
						</Card.Content>
					</Card>

				</Grid.Column> */ }
			</Grid.Row>
		</Grid>
	);
};

const renderServices = () => { 
	return (
		<Grid>
			Services!
		</Grid>
	);
};

const Dashboard: React.FC = () => {    
	return (
		<div>
			{
				renderSummary()
			}
		</div>
	);
};

export default Dashboard;
