import React, { useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Card, Button, Grid, Header, Message, Form } from 'semantic-ui-react'; 
import LoadingSegment from '../../../Components/LoadingSegment';
import ErrorSegment from '../../../Components/ErrorSegment';

const GET_GOOGLE_ANALYTICS = gql`
query GetGoogleAnalytics {
	google_analytics_account {
	  properties {
		views {
		  name
		  ga_view_id
		}
		ga_property_id
		name
	  }
	  name
	  ga_account_id
	}
  }
`;

const GoogleAnalytics: React.FC <{handleChange: ( ( event: any, data: any ) => void ), state: {
    conversionType?:string,
    gaAccount?: string,
    gaProperty?:string
}, clientId?: string}> = ( { handleChange, state, clientId } ) => {
	const { loading, error, data, refetch } = useQuery( GET_GOOGLE_ANALYTICS, { variables: { client_id: { _eq: parseInt( clientId ) } } } );
	const accounts = [];
	const properties = [];
	const views = [];
	if ( data && data.google_analytics_account ) {
		for ( const acc of data.google_analytics_account ) {
			accounts.push( {
				text: acc.name,
				key: acc.ga_account_id,
				value: acc.ga_account_id,
			} );
			for ( const prop of acc.properties ) {
				properties.push( {
					text: prop.name,
					key: prop.ga_property_id,
					value: prop.ga_property_id,
					account: acc.ga_account_id,
				} );
				for ( const view of prop.views ) {
					views.push( {
						text: view.name,
						key: view.ga_view_id,
						value: view.ga_view_id,
						account: acc.ga_account_id,
						property: prop.ga_property_id,
					} );
				}
			}
		}
	}
	return ( <>
		<Header as="h4">Google Analytics</Header>
		<Form.Group widths="equal">
			<Form.Dropdown search fluid selection label="Account" name="gaAccount" loading={ loading } onChange={ handleChange } options={
				accounts
			} />
			<Form.Dropdown search fluid selection label="Property" name="gaProperty" loading={ loading } onChange={ handleChange } disabled={ state.gaAccount ? false : true } options={
				properties.filter( item=> state.gaAccount ? item.account === state.gaAccount : true )
			} />
			<Form.Dropdown search fluid selection label="View" name="gaView" loading={ loading } onChange={ handleChange } disabled={ state.gaAccount && state.gaProperty ? false : true } options={
				views.filter( item=> state.gaAccount && state.gaProperty ? item.account === state.gaAccount && item.property === state.gaProperty : true )
			} />
		</Form.Group>
		<Form.Group inline>
			<label>Conversion Type</label>
			<Form.Radio
				label="Transaction"
				value="transaction"
				name="conversionType"
				checked={ state.conversionType === 'transaction' }
				onChange={ handleChange }
			/>
			<Form.Radio
				label="Goal"
				value="goal"
				name="conversionType"
				checked={ state.conversionType === 'goal' }
				onChange={ handleChange }
			/>
		</Form.Group>
		{ state.conversionType === 'goal' ? 
			<Form.Input label="Goal Number" name="goalNumber" required fluid onChange={ handleChange } />
			: null }
	</> );
};

export default GoogleAnalytics;
