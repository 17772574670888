import * as React from 'react';
import ClientPicker from '../../Components/ClientPicker';
import { Menu, Grid, Form, Input, Header, Button } from 'semantic-ui-react';
import { useRouteMatch, Switch, Route, useHistory, Redirect, Link } from 'react-router-dom';
import ProfileSettings from './ProfileSettings';
import ClientManager from '../ClientManager';

const Settings = () => {
	const history = useHistory();
	const { path } = useRouteMatch();
	console.log( path );
	return (
		<>
			<Menu pointing secondary className="headerAttached" style={ { marginBottom: '50px' } }>
				<Menu.Item
					name="User"
					active={ window.location.pathname === `/settings/user` }
					onClick={ ()=>history.push( `/settings/user` ) }
				/>
				<Menu.Item
					name="Organization"
					active={ window.location.pathname === `/settings/organization` }
					onClick={ ()=>history.push( `/settings/organization` ) }
				/>
				<Menu.Item
					name="Client Manager"
					active={ window.location.pathname === `/settings/client-manager` }
					onClick={ ()=>history.push( `/settings/client-manager` ) }
				/>
			</Menu>
			<Switch>
				<Route path={ '/settings/user' }>
					<ProfileSettings />
				</Route>
				<Route path={ '/settings/organization' }>
					<Form>

					</Form>
				</Route>
				<Route path={ '/settings/client-manager' }>
					<ClientManager />
				</Route>
				<Route path={ '' }>
					<Redirect to="/settings/user" />
				</Route>
			</Switch>
			
		</>
	);
	// }
};

export default Settings;
