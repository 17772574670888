import * as React from 'react';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { LoadingOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Spin, Input, Button, notification, Col, Row } from 'antd';
import LoginGraphic from '../../Images/Graphics/LoginGraphic';
import LogoLogin from '../../Images/logo.png';
import { getUserAuth } from '../../Authentication/GetUser';

type Props = {
	location: any
	form: any;
};

type State = {
	loading: boolean
	redirect: string | null
};

class Login extends React.Component<Props, State> {
	state = {
		loading: false,
		redirect: null,
	};

	async isUserCheck() {
		const user = await getUserAuth( true );
		if ( user !== undefined && user !== null ) {
			notification.success( {
				message: `You are still logged in!`,
				placement: 'topRight',
				duration: 1.5,
			} );
			this.setState( { redirect: '/dashboard' } );
		}
	}

	componentDidMount() {
		console.log( 'LOGIN MOUNT' );
		try {
			this.isUserCheck();
		} catch ( error ) {
			console.log( 'this is not a user' );
		}
	}

	handleSubmit = ( event: React.FormEvent ) => {
		event.preventDefault();
		this.props.form.validateFields( ( err: Error, values: { username: string; password: string } ) => {
			if ( ! err ) {
				const { username, password } = values;
				this.setState( { loading: true } );
				Auth.signIn( username, password )
					.then( user => {
						const { location } = this.props;
						if ( location.state ) {
							console.log( location.state );
							this.setState( { redirect: location.state.referrer } );
						}
						this.setState( { redirect: "/dashboard" } );
					} )
					.catch( err => {
						notification.error( {
							message: 'Error',
							description: err.message,
							placement: 'topRight',
						} );
						console.log( err );
						this.setState( { loading: false } );
					} );
			}
		} );
	};

	render() {
		const { loading, redirect } = this.state;
		if ( redirect ) {
			return <Redirect to={ redirect } />;
		}
		const { getFieldDecorator } = this.props.form;
		if ( this.props.location.pathname === "/" ) {
			return <Redirect to="/login" />;
		}

		return (
			<React.Fragment>
				<div className="LoginBackground">
					<div className="LoginLeftSection">
						<div style={ { padding: '100px', position: 'absolute' } }>
							<img src={ LogoLogin } alt="login-logo" />
							<h1 style={ { color: 'white', fontSize: '55px' } }>Navigate your <br />marketing</h1>
						</div>
						<LoginGraphic />
					</div>
					<div className="LoginRightSection">
						<Form onSubmit={ this.handleSubmit } className="login-form FormWrapper">
							<Row>
								<h2>Sign in</h2>
							</Row>
							<Row gutter={ 16 } style={ { padding: '0px 10px' } }>
								<h5>Email Address</h5>
							</Row>
							<Form.Item>
								{ getFieldDecorator( 'username', {
									rules: [
										{
											required: true,
											message: 'Please input your username!',
										},
									],
								} )(
									<Input prefix={ <UserOutlined /> } />,
								) }
							</Form.Item>
							<Row gutter={ 16 } style={ { padding: '0px 10px' } }>
								<h5>Password</h5>
							</Row>
							<Form.Item>
								{ getFieldDecorator( 'password', {
									rules: [
										{
											required: true,
											message: 'Please input your password!',
										},
									],
								} )(
									<Input
										prefix={ <LockOutlined /> }
										type="password"
									/>,
								) }
							</Form.Item>
							<Form.Item className="text-center">
								<Row gutter={ 16 }>
									<Col lg={ 24 }>
										<Button
											style={ { width: '100%' } }
											type="primary"
											disabled={ loading }
											htmlType="submit"
											className="login-form-button"
										>
											{ loading ? <Spin indicator={ <LoadingOutlined style={ { fontSize: 24 } } spin /> } /> : 'Log in' }
										</Button>
									</Col>
									<Col lg={ 24 }>
										<Link style={ { float: 'right' } } className="login-form-forgot" to="/forgot-password">
											Forgot password
										</Link>
									</Col>
									<Row justify="center" gutter={ 16 } style={ { padding: '20px 10px', width: '100%' } }>
										<div>Not a member? <Link to="/signup"> Register Here</Link></div>
									</Row>
								</Row>
							</Form.Item>
						</Form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Form.create()( Login );
