import React, { useState, useEffect } from 'react';
import { Grid, List, Search, Header, Image, Dropdown, Button, Icon, Container } from 'semantic-ui-react';
import ClientPicker from './ClientPicker';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { getUserAuth } from '../Authentication/GetUser';
import { useQuery, gql } from '@apollo/client';

const handleLogout = async() => {
	try {
		await Auth.signOut( { global: true } ).then( () => {
			window.location.reload();
		} );
	} catch ( err ) {
		console.error( { message: err.message } );
	}
};

// const user = async() => {
// 	const userInfo = await Auth.currentUserInfo();
// 	console.log( userInfo );
// 	return userInfo;
// };

const GET_CURRENT_LOGO = gql`
query GetLogo {
	client_user {
	  active_client_rel {
		logo_thumb
	  }
	  family_name
	  given_name
	}
  }
  
`;

const ProfileMenu: React.FC = ( ) => {
	const history = useHistory();
	const { loading, error, data } = useQuery( GET_CURRENT_LOGO, {
		fetchPolicy: "cache-and-network",
	} );
	if ( loading ) {
		console.log( 'Loading Logo' );
	}
	if ( error ) {
		console.log( error );
	}
	if ( data ) {
		console.log( 'Got Logo' );
		console.log( data );
	}
	const user = data && data.client_user && data.client_user.length === 1 ? data.client_user[ 0 ] : null;
	return (
		<Dropdown icon={ null } floating direction="right" lazyLoad trigger={ <Image src={ data && data.client_user ? `data:image/jpeg;base64,${ btoa( data.client_user[ 0 ].active_client_rel.logo_thumb.replace( '\\x', '' ).match( /\w{2}/g ).map( a => String.fromCharCode( parseInt( a, 16 ) ) ).join( "" ) ) }` : "https://react.semantic-ui.com/images/wireframe/square-image.png" } style={ { height: '50px', filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))' } } circular /> }>
			<Dropdown.Menu>
				{ user ? <Dropdown.Header style={ { textAlign: 'center' } } content={ `${ user && user.given_name ? user.given_name : '' } ${ user && user.family_name ? user.family_name : '' }` } /> : null }
				<Dropdown.Divider />
				<Dropdown.Header>
					<ClientPicker />
				</Dropdown.Header>
				<Dropdown.Divider />
				<Dropdown.Item 
					// icon="settings" 
					onClick={ ()=>history.push( '/settings/user' ) } content="Settings" />
				<Dropdown.Item 
					// icon="settings" 
					onClick={ ()=>history.push( '/support' ) } content="Support" />
				<Dropdown.Item 
					// icon="log out" 
					onClick={ ()=>handleLogout() } style={ { fontWeight: 'bold' } } content="Logout" />
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default ProfileMenu;
