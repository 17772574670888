import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Spin, Button, notification, Input, Col, Form } from 'antd';
import LoginGraphic from '../../Images/Graphics/LoginGraphic';
import LogoLogin from '../../Images/logo.png';
import { Auth } from 'aws-amplify';

type State = {
	username: string;
	loading: boolean;
	redirect: boolean;
	confirmationCode: string;
	error: string;
};

class ConfirmEmail extends React.Component<RouteComponentProps, State> {
	state = {
		username: '',
		loading: false,
		redirect: false,
		confirmationCode: '',
		error: '',
	};

	componentDidMount() {
		if ( this.props.location.search ) {
			// get username from url params
			const username = this.props.location.search.split( '=' )[ 1 ];
			this.setState( { username } );
		}
	}

	handleSubmit = ( event: React.FormEvent ) => {
		event.preventDefault();

		const { confirmationCode } = this.state;

		// show progress spinner
		this.setState( { loading: true } );

		Auth.confirmSignUp( this.state.username, confirmationCode )
			.then( () => {
				this.handleOpenNotification( 'success', 'Succesfully confirmed!', 'You will be redirected to login in a few!' );
			} )
			.catch( err => {
				this.handleOpenNotification( 'error', 'Invalid code', err.message );
				this.setState( {
					loading: false,
				} );
			} );
	};

	/**
	 * @param  {string} - type
	 * @param  {string} - title
	 * @param  {string} - message
	 *
	 * @returns {void} - no value returned
	 */
	handleOpenNotification = ( type: string, title: string, message: string ): void => {
		switch ( type ) {
			case 'success':
				notification.success( {
					message: title,
					description: message,
					placement: 'topRight',
					duration: 1.5,
					onClose: () => {
						this.setState( { redirect: true } );
					},
				} );
				break;

			case 'error':
				notification.error( {
					message: title,
					description: message,
					placement: 'topRight',
					duration: 1.5,
				} );
				break;
		}
	};

	handleOnPaste = ( event: React.ClipboardEvent ) => {
		event.preventDefault();

		const code = event.clipboardData.getData( 'Text' ).trim();

		/** Update input */
		this.setState( { confirmationCode: code } );

		// regex to check if string is numbers only
		const reg = new RegExp( '^[0-9]+$' );

		if ( reg.test( code ) && code.length === 6 ) {
			// code is a valid number

			this.setState( { loading: true } );

			Auth.confirmSignUp( this.state.username, code )
				.then( () => {
					this.handleOpenNotification( 'success', 'Succesfully confirmed!', 'You will be redirected to login in a few!' );
				} )
				.catch( err => {
					this.handleOpenNotification( 'error', 'Invalid code', err.message );
					this.setState( {
						loading: false,
					} );
				} );
		}
	};

	handleChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
		this.setState( { confirmationCode: event.currentTarget.value } );
	};

	render() {
		const { loading, error, confirmationCode, redirect } = this.state;

		return (
			<React.Fragment>
				<div className="LoginBackground">
					<div className="LoginLeftSection">
						<div style={ { padding: '100px', position: 'absolute' } }>
							<img src={ LogoLogin } alt="login-logo" />
							<h1 style={ { color: 'white', fontSize: '55px' } }>Navigate your <br />marketing</h1>
						</div>
						<LoginGraphic />
					</div>
					<div className="LoginRightSection">
						<Form onFinish={ this.handleSubmit }>
							<Col md={ 24 } lg={ 18 }>
								<div className="full-width">
									<h2>Check your email</h2>
									<p>We`&apos;`ve sent a six­ digit confirmation code</p>
								</div>
								<Form.Item validateStatus={ error && 'error' } help={ error } label="Confirmation Code">
									<Input
										size="large"
										type="number"
										placeholder="Enter confirmation code"
										onChange={ this.handleChange }
										onPaste={ this.handleOnPaste }
										value={ confirmationCode }
									/>
								</Form.Item>
							</Col>
							<Col md={ 24 } lg={ 12 }>
								<Button type="primary" disabled={ loading } htmlType="submit" size="large">
									{ loading ? <Spin indicator={ <LoadingOutlined style={ { fontSize: 24 } } spin /> } /> : 'Confirm Email' }
								</Button>
							</Col>
						</Form>
					</div>
				</div>
				{ redirect && <Redirect to={ { pathname: '/login' } } /> }
			</React.Fragment>
		);
	}
}

export default ConfirmEmail;
