// @ts-nocheck
import React, { useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Form, Divider, Cascader, Button, Modal, Input } from 'antd';
import GoogleAnalytics from './GoogleAnalytics';

const layout = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 8,
	},
};

const GET_CURRENT_CLIENT = gql`
query GetCurrentClient {
    client_user {
      active_client_id
    }
  }
`;

const ClientManager: React.FC = () => {
	const [ state, setState ] = useState<State>( {
	} );

	const { loading, error, data } = useQuery( GET_CURRENT_CLIENT );

	if ( loading ) return <p>Loading...</p>;
	if ( error ) return <p>Error :(</p>;

	return (
		<>
			<div style={ { textAlign: 'center', width: '100%', marginTop: '25px' } }>
				
				{ data && data.client_user ? <>
					<h1>Data Management</h1>
					<GoogleAnalytics client_id={ data.client_user[ 0 ].active_client_id } />
					<h3>Google Search Console</h3>
					<h3>Google Ads</h3>
					<h3>Bing Ads</h3>
					<h3>Facebook Ads</h3>
					<h3>Taboola</h3>
				</>
					: null }
			</div>

		</>
	);
};

export default ClientManager;
