import React, { FC, useState } from 'react';
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import { Grid, Card, Message, Button, Menu, Icon, Modal, Header } from 'semantic-ui-react';
import { useParams, Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { v4 } from 'uuid';
import '../css/sonar.css';

const GET_CLIENT_BY_UUID = gql`
query ClientTestList($client_id: uuid!) {
  client_client_by_pk(client_id: $client_id) {
    client_id
    name
    description
    updated
    client_client_test {
      test {
        test_id
        name
        description
        enabled
        cron_schedule
        log_bucket
        log_path
        network_capture_config
        start_url
        test_status
        steps {
          step_id
          sequence
          name
          type
          action_type
          config
          updated
        }
      }
    }
  }
}
`;

const ADD_NEW_TEST = gql`
mutation addNewTest($client_id: uuid!, $test_id: uuid!, $created: timestamp!, $updated: timestamp!) {
  insert_sonar_test(objects: {client_tests: {data: {client_id: $client_id}}, test_id: $test_id, name: "Untitled", enabled: true, created: $created, updated: $updated}) {
    returning {
      client_tests {
        client_id
      }
      test_id
      enabled
    }
  }
}
`
const GET_ACTIVE_CLIENT = gql`
query GetActiveClient {
	client_user {
	  active_client_id
	}
  }  
`;

//List Client Includes the Aggrigate of Current Tests
// type ListClient = {
// 	client_id: string,
// 	name: string,
// 	description: string,
// 	client_client_test_aggregate: {
// 		aggregate: {
// 			count: number
// 		}
// 	}
// }

type SonarClient = {
  client_id: string,
  name: string,
  description: string,
  updated: string,
  client_client_test: [
    { test: {
        test_id: string,
        name: string,
        description: string,
        enabled: boolean,
        network_capture_config: string,
        start_url: string,
        test_status: string,
        steps: [
          { step: {
              step_id: string,
              sequence: number,
              name: string,
              type: string,
              action_type: string,
              config: string
              updated: string
            }
          }
        ]
      }
    }
  ]
}

type clientTest = {
  test: {
    test_id: string,
    name: string,
    description: string,
    enabled: boolean,
    network_capture_config: string,
    start_url: string,
    test_status: string,
    steps: [
      { step: {
          step_id: string,
          sequence: number,
          name: string,
          type: string,
          action_type: string,
          config: string
          updated: string
        }
      }
    ]
  }
}

type testMessage = {
  text: string
}

const ViewClient: FC = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessageState] = useState<testMessage>(
      {
        text: "No Tests Have been Initiated"
      }
    )

    let { client_id } = useParams();
    const history = useHistory();
    
    //console.log("client_id")
    //console.log(client_id)
    const { loading, error, data, refetch } = useQuery(GET_CLIENT_BY_UUID, {
        variables: { client_id }
    });

    const { loading: l, error: e, data: current, refetch: r } = useQuery( GET_ACTIVE_CLIENT, {
      fetchPolicy: "no-cache",
    } );

    //Update Test
    const [
      addTest,
      { loading: mutationLoading, error: mutationError }
    ] = useMutation(ADD_NEW_TEST, {
      onCompleted: (data) => {
        if(data.insert_sonar_test.returning[0].client_tests[0].client_id){
          let newPath = `/sonar/client/${data.insert_sonar_test.returning[0].client_tests[0].client_id}/edit_test/${data.insert_sonar_test.returning[0].test_id}`
          history.push(newPath)
        }
      }
    });

    //Use Apollo's loading feature to prevent errors
    if (loading || l) {
        return null;
    }

    //console.log("CD Data");
    //console.log(clientListData);

    //Creates a New Test
    const addNewTest = (e, data) => {
      //console.log("AddingTest");
      //console.log(e)
      const currentTime = new Date();
      addTest({
        variables: {client_id: client_id, test_id: v4(), created: currentTime.toISOString(), updated: currentTime.toISOString()} 
      })
    }

    //Invokes a Single Test
    const runSingleTest = (e, data) => {
      let inputSting = "{\"data\": {\"run_type\": \"SINGLE_TEST\",\"client_id\": \"" + data.client_id + "\",\"test_id\": \"" + data.test_id +"\"}}"
      let bodyData = JSON.stringify({
        "input": inputSting,
        "name": "Invoke_Single_Client_Test_" + Date.now(),
        "stateMachineArn": "arn:aws:states:us-west-2:145992106078:stateMachine:SonarRunTestsMachine"
      });
      
      //Simple Post to Endpoint to Invoke Test
      fetch(
        `https://w5l9eswd59.execute-api.us-west-2.amazonaws.com/v1/execute`,
        {
          method: "POST",
          headers: new Headers({ 
            'Content-Type': 'application/json'
          }),
          body: bodyData
        }
      )
        .then(res => res.json())
        .then(response => {
          let msgResponse: testMessage = {
            text: JSON.stringify(response)
          }
          setMessageState(msgResponse)
          setOpen(true);
        }
        )
        .catch(error => console.log(error));
    }

    const runAllTests = (e, data) => {
      let inputSting = "{\"data\": {\"run_type\": \"ALL_TESTS\",\"client_id\": \"" + data.client_id + "\"}}"
      let bodyData = JSON.stringify({
        "input": inputSting,
        "name": "Invoke_All_Client_Tests_" + Date.now(),
        "stateMachineArn": "arn:aws:states:us-west-2:145992106078:stateMachine:SonarRunTestsMachine"
      });
      
      //Simple Post to Endpoint to Invoke Test
      fetch(
        `https://w5l9eswd59.execute-api.us-west-2.amazonaws.com/v1/execute`,
        {
          method: "POST",
          headers: new Headers({ 
            'Content-Type': 'application/json'
          }),
          body: bodyData
        }
      )
        .then(res => res.json())
        .then(response => {
          let msgResponse: testMessage = {
            text: JSON.stringify(response)
          }
          setMessageState(msgResponse)
          setOpen(true);
        }
        )
        .catch(error => console.log(error));
    }

    const renderTestCard = (client, client_test) => (
      <Card>
        {/* <Image src='/images/avatar/large/matthew.png' wrapped ui={false} /> */}
        <Card.Content>
          <Card.Header>{client_test.test.name}</Card.Header>
          <Card.Meta>
            <span className='test-updated'>{client_test.test.updated}</span>
          </Card.Meta>
          <Card.Description>
            {client_test.test.test_status == "PASSING" &&
            <Message
              icon='check'
              color='green'
              header='Test Passing!'
            />}
            {client_test.test.test_status == "FAILING" &&
            <Message
              icon='warning circle'
              color='red'
              header='Test Failing!'
            />}
            <p>{client_test.test.description}</p>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Link to={`/sonar/client/${client.client_id}/view_test/${client_test.test.test_id}`}><Button primary>View</Button></Link>
          <Button color='green' client_id={client.client_id} test_id={client_test.test.test_id} onClick={runSingleTest}>Run Test</Button>
          <Link to={`/sonar/client/${client.client_id}/edit_test/${client_test.test.test_id}`}><Button secondary>Edit</Button></Link>
        </Card.Content>
      </Card>
    )

    const renderClient = (client) => {
      const redirectString = `/sonar/client/${current.client_user[ 0 ].active_client_id}`
      return (
        <div>
        {current.client_user[ 0 ].active_client_id !== client_id && 
          <Redirect to={redirectString} />
        }
        <Menu pointing secondary className="headerAttached" style={{ marginBottom: '50px' }}>
            <Menu.Item name="Add New Test Flow" onClick={addNewTest}>
              <Icon name='plus' color="green" /><span>Add New Test</span>
              {mutationLoading && <p>Loading...</p>}
              {mutationError && <p>Error :( Please try again</p>}
            </Menu.Item>
        </Menu>
        <Grid container id="testGrid" stackable relaxed="very" style={ { marginTop: '50pxƒ' } }>
          <Grid.Row>
              <Header style={{
                fontFamily: 'Montserrat',
                fontWeight: 600,
                fontSize: '30px',
                color: '#2d393b',
                height: '65px'
              }}>{data.client_client_by_pk.name} - Sonar Test Flows</Header>
				    <Grid.Column width={ 16 }>
            <Card.Group>
              { client.client_client_test.map((clientTest: clientTest) => { 
                return renderTestCard(client, clientTest)
              })}
            </Card.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
				    <Grid.Column width={ 16 }>
              <Button primary client_id={client.client_id} onClick={runAllTests}>Run All Tests for {client.name}</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
          <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
          >
            <Modal.Header>Testing Initiated</Modal.Header>
            <Modal.Content>
              <Modal.Description content={message.text} />
            </Modal.Content>
            <Modal.Actions>
              <Button
                content="Ok"
                labelPosition='right'
                icon='checkmark'
                onClick={() => setOpen(false)}
                primary
              />
            </Modal.Actions>
          </Modal>
        </div>
      )
    }

    return(
      <div>
        { renderClient(data.client_client_by_pk) }
      </div>
    )
}

export default ViewClient;
