// @ts-nocheck
import React, { FC, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Grid, Form, Input, Button, TextArea, Icon, Accordion, Confirm, GridRow, Header, Menu } from 'semantic-ui-react';
import { useParams, useHistory, Link } from "react-router-dom";
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/solarized_dark';
import StepForm from '../Forms/StepForm'
import { deepParseJson } from 'deep-parse-json';
import { v4 } from 'uuid';
import AssertionForm from './AssertionForm';
import '../css/sonar.css';

const GET_TEST = gql`
query getClientTest($client_id: uuid!, $test_id: uuid!) {
  sonar_client_test(where: {client_id: {_eq: $client_id}, test_id: {_eq: $test_id}}) {
    client_id
    test {
      test_id
      name
      description
      enabled
      log_bucket
      log_path
      network_capture_config
      start_url
      cron_schedule
      steps {
          step_id
          sequence
      }
      assertions {
          assertion_id
      }
    }
  }
}
`;

const UPDATE_TEST = gql`
mutation UpdateTest($test_id: uuid!, $name: String!, $description: String!, $start_url: String!, $log_bucket: String!, $log_path: String!, $network_capture_config: jsonb!, $enabled: Boolean!, $updated: timestamp!, $cron_schedule: String!) {
  update_sonar_test_by_pk(pk_columns: {test_id: $test_id}, _set: {name: $name, description: $description, start_url: $start_url, log_bucket: $log_bucket, log_path: $log_path, network_capture_config: $network_capture_config, enabled: $enabled, cron_schedule: $cron_schedule, updated: $updated}) {
    test_id
    name
    description
    network_capture_config
    start_url
    log_bucket
    log_path
    cron_schedule
    enabled
  }
}
`

const ADD_NEW_STEP = gql`
mutation AddNewStep($test_id: uuid!, $step_id: uuid!, $sequence: Int!, $created: timestamp!, $updated: timestamp!) {
  insert_sonar_step(objects: {test_id: $test_id, step_id: $step_id, name: "New Step", sequence: $sequence, type: "NAV", action_type: "GOTO", created: $created, updated: $updated, config: "{}"}) {
    returning {
      step_id
      name
      type
      action_type
      config
      sequence
      updated
    }
  }
}
`

const ADD_NEW_ASSERTION = gql`
mutation AddNewAssertion($test_id: uuid!, $assertion_id: uuid!, $created: timestamp!, $updated: timestamp!) {
  insert_sonar_assertion(objects: {test_id: $test_id, assertion_id: $assertion_id, name: "New Assertion", created: $created, updated: $updated, type: "EQUAL", config: "{}"}) {
    returning {
      assertion_id
      name
      type
      config
      updated
      created
    }
  }
}
`

const DELETE_TEST = gql`
mutation DeleteTest($client_id: uuid = "", $test_id: uuid!) {
  delete_sonar_client_test(where: {client_id: {_eq: $client_id}, test_id: {_eq: $test_id}}) {
    affected_rows
    returning {
      client_id
    }
  }
  delete_sonar_test(where: {test_id: {_eq: $test_id}}) {
    affected_rows
    returning {
      test_id
    }
  }
}
`


type ClientTest = {
    client_id: string,
    test: {
        test_id: string,
        name: string,
        description: string,
        enabled: boolean,
        network_capture_config: string,
        start_url: string,
        log_bucket: string,
        log_path: string
        cron_schedule: string
        steps?: any[]
        assertions?: any[]
    }
}

type TestStep ={
    step_id: string,
    sequence: number
}

type TestAssertion = {
    assertion_id: string,
    name: string,
    type: string,
    config: string
}

interface confirmDialog {
    open: boolean,
    result: string
}


const TestForm: FC = () => {
    let { client_id, test_id } = useParams();
    let stepsSorted;
    const history = useHistory();

    //Setup Test State
    const [ testState, setTestState ] = useState<ClientTest>();

    //Dialog State
    const [ confirmState, setConfirmState ] = useState<confirmDialog>({
        open: false,
        result: ""
    });

    //Get Test Apollo Query
    const { loading: queryLoading, error: queryError, data } = useQuery(
        GET_TEST, 
        {
            variables: { client_id: client_id, test_id: test_id },
        }
    );

    //Update Test
    const [
        updateTest,
        { loading: mutationLoading, error: mutationError }
    ] = useMutation(UPDATE_TEST);

    //Add Step
    const [
        addStep,
        { loading: mutationStepLoading, error: mutationStepError }
    ] = useMutation(ADD_NEW_STEP, {
        onCompleted: (data) => {
          if(data.insert_sonar_step.returning[0].step_id){
            //Brute force for now :P
            window.location.reload();
          }
        }
      });

    //Add Step
    const [
        addAssertion,
        { loading: mutationAssertionLoading, error: mutationAssertionError }
    ] = useMutation(ADD_NEW_ASSERTION, {
        onCompleted: (data) => {
          if(data.insert_sonar_assertion.returning[0].assertion_id){
            //Brute force for now :P
            window.location.reload();
          }
        }
      });

    //Delete Test
    const [
        deleteTest,
        { loading: mutationDeleteLoading, error: mutationDeleteError }
    ] = useMutation(DELETE_TEST, {
        onCompleted: (data) => {
          if(data.delete_sonar_test.affected_rows){
            history.push(`/sonar/client/${data.delete_sonar_client_test.returning[0].client_id}/`)
            //Brute force state refresh for now
            window.location.reload();
          }
        }
      });

    if (queryLoading) return <p>Loading...</p>;
    if (queryError) return <p>Error :(</p>;

    //We are mapping the test to State for ease of use.
    data.sonar_client_test.map((client_test: ClientTest) => {
        if(!testState){
            setTestState(client_test);
        }
    })

    if(!testState){
        return <p>Loading...</p>;
    }

    stepsSorted = testState.test.steps?.slice().sort((a: TestStep, b: TestStep) => a.sequence > b.sequence ? 1 : -1)

    const addNewStep = (e, data) => {
        const currentTime = new Date();
        const sequenceNum = stepsSorted.length +1 || 1;
        
        addStep({variables: { test_id: testState.test.test_id, step_id: v4(), sequence: sequenceNum, created: currentTime.toISOString(), updated: currentTime.toISOString()}})
    } 

    const addNewAssertion = (e, data) => {
        const currentTime = new Date();
        addAssertion({variables: { test_id: testState.test.test_id, assertion_id: v4(), created: currentTime.toISOString(), updated: currentTime.toISOString()}})
    } 

    const handleDelete = (e, value) => {
        deleteTest({variables: {client_id: testState.client_id, test_id: testState.test.test_id }})
    }

    const handleCancel = (e, value) => {
        setConfirmState({ open: false, result: value })
    }
    

    return (
        <div>
            <Menu pointing secondary className="headerAttached" style={{ marginBottom: '50px' }}>
                <Menu.Item name="Back to Test Flow List">
                    <Link to={`/sonar/client/${testState.client_id}`}><Icon name='angle left'/><span>Back to Test Flow List</span></Link>
                </Menu.Item>
            </Menu>
            <Grid container id="testFormHeader" stackable relaxed="very" >
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header style={{
                            fontFamily: 'Montserrat',
                            fontWeight: 600,
                            fontSize: '30px',
                            color: '#2d393b',
                            height: '65px'
                        }}>Editing {testState.test.name}</Header>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid container id="testForm" stackable relaxed="very" style={{ marginTop: '50pxƒ' }}>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <h3>Edit Test</h3>
                        <Form onSubmit={e => {
                            let updated = new Date();
                            e.preventDefault();
                            updateTest({ variables: { test_id: testState.test.test_id, name: testState.test.name, description: testState.test.description, start_url: testState.test.start_url, log_bucket: testState.test.log_bucket, log_path: testState.test.log_path, network_capture_config: testState.test.network_capture_config, enabled: testState.test.enabled, updated:  updated.toISOString(), cron_schedule: testState.test.cron_schedule} });
                        }}>
                            <Form.Field
                                id='form-input-control-test-name'
                                control={Input}
                                value={testState.test.name || ""}
                                label='Name'
                                placeholder='Test name'
                                onChange={(e, { value }) => setTestState({
                                    client_id: testState.client_id,
                                    test: {
                                        test_id: testState.test.test_id,
                                        name: value,
                                        description: testState.test.description,
                                        enabled: testState.test.enabled,
                                        network_capture_config: testState.test.network_capture_config,
                                        start_url: testState.test.start_url,
                                        log_bucket: testState.test.log_bucket,
                                        log_path: testState.test.log_path,
                                        cron_schedule: testState.test.cron_schedule,
                                        steps: testState.test.steps
                                    }
                                }) }
                            />
                            <Form.Field
                                id='form-textarea-control-test-description'
                                control={TextArea}
                                value={testState.test.description}
                                label='Description'
                                placeholder='Test description'
                                onChange={(e, { value }) => setTestState({
                                    client_id: testState.client_id,
                                    test: {
                                        test_id: testState.test.test_id,
                                        name: testState.test.name,
                                        description: value,
                                        enabled: testState.test.enabled,
                                        network_capture_config: testState.test.network_capture_config,
                                        start_url: testState.test.start_url,
                                        log_bucket: testState.test.log_bucket,
                                        log_path: testState.test.log_path,
                                        cron_schedule: testState.test.cron_schedule,
                                        steps: testState.test.steps
                                    }
                                }) }
                            />
                            <Form.Field
                                id='form-textarea-control-test-start-url'
                                control={Input}
                                value={testState.test.start_url}
                                label='Start URL'
                                placeholder='https://page-to-test.com'
                                onChange={(e, { value }) => setTestState({
                                    client_id: testState.client_id,
                                    test: {
                                        test_id: testState.test.test_id,
                                        name: testState.test.name,
                                        description: testState.test.description,
                                        enabled: testState.test.enabled,
                                        network_capture_config: testState.test.network_capture_config,
                                        start_url: value,
                                        log_bucket: testState.test.log_bucket,
                                        log_path: testState.test.log_path,
                                        cron_schedule: testState.test.cron_schedule,
                                        steps: testState.test.steps
                                    }
                                }) }
                            />
                            <Editor
                                value={deepParseJson(testState.test.network_capture_config)}
                                mode='code'
                                ace={ace}
                                theme="ace/theme/solarized_dark"
                                onChange={(json) => {
                                    if(json){
                                        setTestState({
                                            client_id: testState.client_id,
                                            test: {
                                                test_id: testState.test.test_id,
                                                name: testState.test.name,
                                                description: testState.test.description,
                                                enabled: testState.test.enabled,
                                                network_capture_config: JSON.stringify(json),
                                                start_url: testState.test.start_url,
                                                log_bucket: testState.test.log_bucket,
                                                log_path: testState.test.log_path,
                                                cron_schedule: testState.test.cron_schedule,
                                                steps: testState.test.steps
                                            }
                                        })
                                    }
                                } }
                            />
                            <p></p>
                            <Form.Group>
                                <Form.Field
                                    id='form-button-control-public-save-test'
                                    control={Button}
                                    type="submit"
                                    content='Save Test'
                                    primary
                                />
                                {mutationLoading && <p>Loading...</p>}
                                {mutationError && <p>Error :( Please try again</p>}
                                <Button color='red' onClick={ (e, value) => {setConfirmState({...confirmState, open: true})}} >Delete Test</Button>
                                <Confirm
                                    open={confirmState.open}
                                    content='Permanently delete this Test?'
                                    onCancel={handleCancel}
                                    onConfirm={handleDelete}
                                    negative
                                />
                                {mutationDeleteLoading && <p>Loading...</p>}
                                {mutationDeleteError && <p>Error :( Please try again</p>}
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        {stepsSorted.length > 0 &&
                            <Grid.Row>
                                <h3>Edit Steps</h3>
                                <Accordion fluid styled as={Form.Field}>
                                    { stepsSorted.map((step: TestStep, index) => ( <StepForm key={step.step_id} step_id={step.step_id} index={index} /> ) )}
                                </Accordion>
                            </Grid.Row>
                        }
                        <p></p>
                        <Button as={Form.Field} secondary onClick={addNewStep}>Add New Step</Button>
                        {testState.test.assertions?.length > 0 &&
                            <Grid.Row>
                                <p></p>
                                <h3>Edit Assertions</h3>
                                <Accordion fluid styled as={Form.Field}>
                                    { testState.test.assertions?.map((assertion: TestAssertion, index) => ( <AssertionForm key={assertion.assertion_id} assertion_id={assertion.assertion_id} index={index} /> ) )}
                                </Accordion>
                            </Grid.Row>
                        }
                        <p></p>
                        <Button as={Form.Field} secondary onClick={addNewAssertion}>Add New Assertion</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div >
    )
    
}

export default TestForm;